<template>
  <LoaderPlaceholder
    v-if="loading"
  />
  <form v-else>
    <v-tabs
      v-if="!isNew()"
      v-model="tab"
      class="mb-4"
      background-color="primary"
      center-active
    >
      <v-tab value="one">
        General
      </v-tab>
    </v-tabs>
    <v-window
      v-model="tab"
      class="py-2"
    >
      <v-window-item value="one">
        <page-top-area
          :header-text="isNew() ? 'New info card' : 'Edit ' + question.substring(0, 10)"
          :info-text="'Manage a info card'"
        >
          <template #button-area>
            <v-row>
              <v-col cols="6">
                <div v-if="!isNew()">
                  <div v-if="active">
                    <v-btn
                      prepend-icon="mdi-eye"
                      variant="text"
                      color="#4485ca"
                      @click="handleSwitchActive"
                    >
                      Public
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-btn
                      prepend-icon="mdi-eye-off"
                      variant="text"
                      color="#4485ca"
                      @click="handleSwitchActive"
                    >
                      Hidden
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col cols="6">
                <v-btn
                  prepend-icon="mdi-delete"
                  variant="text"
                  color="#4485ca"
                  @click="handleClickDelete"
                >
                  Delete
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </page-top-area>
        <v-row>
          <v-col cols="12">
            <InfoCardTemplateSelection
              v-model="infoCardTemplate"
              :v$="v$"
              :geo-base-uuid="geoBaseUuid"
              :error-messages="infoCardTemplateErrors"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="question"
              :error-messages="questionErrors"
              :counter="1000"
              label="Question"
              required
              :readonly="!canEditQuestion()"
              @input="v$.question.$touch()"
              @blur="v$.question.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="aiQuestion"
              :error-messages="aiQuestionErrors"
              :counter="1000"
              label="Ai Question (if different from question)"
              required
              :readonly="!canEditQuestion()"
              @input="v$.aiQuestion.$touch()"
              @blur="v$.aiQuestion.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <QuillTextarea
              v-if="!loading"
              v-model:content="answer"
              placeholder="Answer"
            />
            <v-btn
              class="float-right"
              variant="text"
              color="#4485ca"
              @click="()=> handleAskWizard()"
            >
              Ask wizard
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="link"
              :error-messages="linkErrors"
              :counter="1000"
              label="Link"
              required
              @input="v$.link.$touch()"
              @blur="v$.link.$touch()"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="linkText"
              :error-messages="linkTextErrors"
              :counter="1000"
              label="Link Text"
              required
              @input="v$.linkText.$touch()"
              @blur="v$.linkText.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="authorName"
              :error-messages="authorNameErrors"
              :counter="1000"
              label="Author Name"
              required
              @input="v$.authorName.$touch()"
              @blur="v$.authorName.$touch()"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="authorLink"
              :error-messages="authorLinkErrors"
              :counter="1000"
              label="Author Link"
              required
              @input="v$.authorLink.$touch()"
              @blur="v$.authorLink.$touch()"
            />
          </v-col>
        </v-row>

        <v-row justify="space-between">
          <v-col
            cols="6"
          />
          <v-col
            cols="6"
          >
            <v-btn
              :disabled="(isNew() && !v$.$dirty) || v$.$error"
              color="primary"
              @click="handleSave"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-window-item>
      <v-window-item value="links">
        <v-row>
          <v-col cols="12">
            <vue-good-table
              :columns="linkColumns"
              :rows="links"
              :pagination-options="{
                enabled:false
              }"
              style-class="vgt-table"
            >
              <template #table-row="props">
                <span v-if="props.column.field === 'tags'">
                  <div class="text-caption">
                    {{ props.row.tags }}
                  </div>
                </span>
                <span v-else-if="props.column.field === 'delete'">
                  <v-btn
                    icon
                    size="small"
                    @click="handleClickDeleteLink(props.row.uuid)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <GeoBaseTypeSelection
              v-model:selected-value="newLinkGeoBaseType"
              :v$="v$"
            />
          </v-col>
          <v-col cols="4">
            <TagSelection
              v-model:selected-values="newLinkTags"
              :v$="v$"
            />
          </v-col>
          <v-col
            cols="3"
            class="d-flex align-center justify-end"
          >
            <v-btn
              :disabled="newLinkGeoBaseType === null"
              color="primary"
              @click="handleAddLink"
            >
              Add Link
            </v-btn>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
  </form>
  <ConfirmationDialog
    :open="deleteModalOpen"
    :on-abort="handleAbortDeleteModal"
    :on-confirm="handleConfirmDeleteModal"
    :text="'Do you really want to delete?'"
  />
  <RequestErrorNotification :error="submitError" />
</template>

<script>
import {
  maxLength, minLength,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { getErrorMessages, minLengthWithoutHtml } from '@/utils/validationUtils';
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import PageTopArea from '@/components/PageTopArea.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import TagSelection from '@/components/TagSelection.vue';
import GeoBaseTypeSelection from '@/components/GeoBaseTypeSelection.vue';
import QuillTextarea from '@/components/QuillTextarea.vue';
import InfoCardTemplateSelection from '@/components/InfoCardTemplateSelection.vue';

export default {
  components: {
    InfoCardTemplateSelection,
    QuillTextarea,
    GeoBaseTypeSelection,
    TagSelection,
    PageTopArea,
    ConfirmationDialog,
  },
  validations: {
    question: {
      maxLength: maxLength(50),
      minLength: minLength(3),
    },
    aiQuestion: {
      maxLength: maxLength(50),
    },
    answer: {
      // maxLength: maxLength(1500),
      minLength: minLengthWithoutHtml(5),
    },
    link: {
      maxLength: maxLength(250),
      minLength: minLength(0),
    },
    linkText: {
      maxLength: maxLength(150),
      minLength: minLength(0),
    },
    authorName: {
      maxLength: maxLength(50),
      minLength: minLength(0),
    },
    authorLink: {
      maxLength: maxLength(150),
      minLength: minLength(0),
    },
    infoCardTemplate: {},
  },
  data() {
    return ({
      loading: !this.isNew(),
      submitError: null,
      uuid: this.isNew() ? null : this.$route.params.uuid,
      geoBaseUuid: this.$route.params.geoBaseUuid,
      geoBaseTypePath: this.$route.params.geoBaseTypePath,
      v$: useVuelidate(),
      tab: null,
      deleteModalOpen: false,
      active: false,
      question: '',
      aiQuestion: '',
      answer: '',
      link: '',
      linkText: '',
      authorName: '',
      authorLink: '',
      infoCardTemplate: null,
      infoCardTemplateUuidFromUrl: this.$route.query.infoCardTemplateUuid || null,
    });
  },
  computed: {
    questionErrors() {
      return getErrorMessages(this.v$.question, 'Question');
    },
    aiQuestionErrors() {
      return getErrorMessages(this.v$.aiQuestion, 'Ai Question');
    },
    answerErrors() {
      return getErrorMessages(this.v$.aiQuestion, 'Ai Question');
    },
    infoCardTemplateErrors() {
      return getErrorMessages(this.v$.infoCardTemplate, 'Template');
    },
    linkErrors() {
      return getErrorMessages(this.v$.link, 'Link');
    },
    linkTextErrors() {
      return getErrorMessages(this.v$.linkText, 'Link Text');
    },
    authorNameErrors() {
      return getErrorMessages(this.v$.authorName, 'Author name');
    },
    authorLinkErrors() {
      return getErrorMessages(this.v$.authorLink, 'Author link');
    },
  },
  watch: {
    infoCardTemplate() {
      if (!this.infoCardTemplate) {
        return;
      }
      if (this.infoCardTemplate.uuid != null) {
        this.question = this.infoCardTemplate.question;
        this.aiQuestion = this.infoCardTemplate.aiQuestion;
      }
    },
  },
  mounted() {
    this.loadBasicData();
    this.touchIgnoreableElements();
    this.handlePreSelectedInfoCardTemplate();
  },
  methods: {
    isNew() {
      return this.$route.params.uuid === 'new';
    },
    loadBasicData() {
      if (!this.uuid) {
        return;
      }
      axios.get(`${getBackendAdminHost(this.$store)}/admin/info-card/${this.uuid}`, { withCredentials: true })
        .then((response) => {
          this.applyResponseData(response.data);
          this.loading = false;
        })
        .catch(() => {
          this.submitError = 'Error during request';
        });
    },
    applyResponseData(data) {
      this.question = data.question;
      this.aiQuestion = data.aiQuestion;
      this.answer = data.answer;
      this.link = data.link;
      this.linkText = data.linkText;
      this.authorName = data.authorName;
      this.authorLink = data.authorLink;
      this.infoCardTemplate = data.infoCardTemplate;
    },
    handleClickDelete() {
      this.deleteModalOpen = true;
    },
    handleAbortDeleteModal() {
      this.deleteModalOpen = false;
    },
    buildRequestObject() {
      return {
        geoBaseUuid: this.geoBaseUuid,
        question: this.question,
        aiQuestion: this.aiQuestion,
        answer: this.answer,
        link: this.link,
        linkText: this.linkText,
        authorName: this.authorName,
        authorLink: this.authorLink,
        infoCardTemplateUuid: this.infoCardTemplate?.uuid,
      };
    },
    create(requestBody) {
      axios.post(`${getBackendAdminHost(this.$store)}/admin/info-card`, requestBody, { withCredentials: true })
        .then((response) => {
          this.loading = false;
          this.uuid = response.data.uuid;
          this.$router.replace(`/info-card/${this.geoBaseTypePath}/${this.geoBaseUuid}/${response.data.uuid}`);
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update(requestBody) {
      axios.put(`${getBackendAdminHost(this.$store)}/admin/info-card/${this.uuid}`, requestBody, { withCredentials: true })
        .then(() => {
          this.$router.push(`/${this.geoBaseTypePath}/${this.geoBaseUuid}#info-cards`);
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    handleConfirmDeleteModal() {
      axios.delete(`${getBackendAdminHost(this.$store)}/admin/info-card/${this.uuid}`, { withCredentials: true })
        .then(() => {
          this.$router.back();
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    handleSave() {
      this.loading = true;

      const requestBody = this.buildRequestObject();

      if (this.isNew()) {
        this.create(requestBody);
      } else {
        this.update(requestBody);
      }
    },
    handleSwitchActive() {
      this.loading = true;
      axios.post(`${getBackendAdminHost(this.$store)}/admin/info-card/${this.uuid}/switch-active`, {}, { withCredentials: true })
        .then(
          () => {
            this.active = !this.active;
          },
        )
        .catch(() => {
          this.submitError = 'Error - maybe it doesn\'t have enough data';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleAskWizard() {
      this.loading = true;
      axios.post(`${getBackendAdminHost(this.$store)}/admin/ai-wizard/${this.geoBaseUuid}/answer-info-card`, { question: this.aiQuestion || this.question }, { withCredentials: true })
        .then(
          (response) => {
            this.answer = response.data;
          },
        )
        .catch(() => {
          this.submitError = 'Error requesting wizard';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePreSelectedInfoCardTemplate() {
      if (!this.infoCardTemplateUuidFromUrl || this.uuid !== null) {
        return;
      }

      axios.get(`${getBackendAdminHost(this.$store)}/admin/info-card-template/${this.infoCardTemplateUuidFromUrl}`, { withCredentials: true })
        .then((response) => {
          this.infoCardTemplate = response.data;
        });
    },
    touchIgnoreableElements() {
      this.v$.aiQuestion.$touch();
      this.v$.question.$touch();
      this.v$.answer.$touch();
      this.v$.linkText.$touch();
      this.v$.link.$touch();
      this.v$.authorName.$touch();
      this.v$.authorLink.$touch();
      this.v$.infoCardTemplate.$touch();
    },
    canEditQuestion() {
      return !this.infoCardTemplate || !this.infoCardTemplate.uuid;
    },
  },

};
</script>
