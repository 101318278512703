<template>
  <LoaderPlaceholder
    v-if="loading"
  />
  <form v-else>
    <page-top-area
      :header-text="isNew() ? 'New agency' : 'Edit ' + name"
      :info-text="'Manage a agency'"
    >
      <template #button-area>
        <v-row>
          <v-col cols="6" />
          <v-col cols="6">
            <v-btn
              prepend-icon="mdi-delete"
              variant="text"
              color="#4485ca"
              @click="handleClickDelete"
            >
              Delete
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </page-top-area>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="name"
          :error-messages="nameErrors"
          :counter="50"
          label="Name"
          required
          @input="v$.name.$touch()"
          @blur="v$.name.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="url"
          :error-messages="urlErrors"
          :counter="255"
          label="Url"
          required
          @input="v$.url.$touch()"
          @blur="v$.url.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <QuillTextarea
          v-if="!loading"
          v-model:content="description"
          placeholder="Description"
        />
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col
        cols="6"
      />
      <v-col
        cols="6"
      >
        <v-btn
          :disabled="(isNew() && !v$.$dirty) || v$.$error"
          color="primary"
          @click="handleSave"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
  </form>
  <ConfirmationDialog
    :open="deleteModalOpen"
    :on-abort="handleAbortDeleteModal"
    :on-confirm="handleConfirmDeleteModal"
    :text="'Do you really want to delete?'"
  />
  <RequestErrorNotification :error="submitError" />
</template>

<script>
import {
  maxLength, minLength,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { getErrorMessages } from '@/utils/validationUtils';
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import QuillTextarea from '@/components/QuillTextarea.vue';
import PageTopArea from '@/components/PageTopArea.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';

export default {
  components: {
    PageTopArea,
    ConfirmationDialog,
    QuillTextarea,
  },
  validations: {
    name: {
      maxLength: maxLength(50),
      minLength: minLength(3),
    },
    url: {
      maxLength: maxLength(255),
    },
  },
  data() {
    return ({
      loading: !this.isNew(),
      submitError: null,
      uuid: this.isNew() ? null : this.$route.params.uuid,
      v$: useVuelidate(),
      deleteModalOpen: false,
      name: '',
      url: null,
      description: null,
    });
  },
  computed: {
    nameErrors() {
      return getErrorMessages(this.v$.name, 'Name');
    },
    urlErrors() {
      return getErrorMessages(this.v$.url, 'Url');
    },
    descriptionErrors() {
      return getErrorMessages(this.v$.description, 'Description');
    },
  },
  mounted() {
    this.loadBasicData();
    this.touchIgnoreableElements();
  },
  methods: {
    isNew() {
      return this.$route.params.uuid === 'new';
    },
    loadBasicData() {
      if (!this.uuid) {
        return;
      }
      axios.get(`${getBackendAdminHost(this.$store)}/admin/agency/${this.uuid}`, { withCredentials: true })
        .then((response) => {
          this.applyResponseData(response.data);
          this.loading = false;
        })
        .catch(() => {
          this.submitError = 'Error during request';
        });
    },
    applyResponseData(data) {
      this.name = data.name;
      this.description = data.description;
      this.url = data.url;
    },
    handleClickDelete() {
      this.deleteModalOpen = true;
    },
    handleAbortDeleteModal() {
      this.deleteModalOpen = false;
    },
    buildRequestObject() {
      return {
        name: this.name,
        url: this.url,
        description: this.description,
      };
    },
    create(requestBody) {
      axios.post(`${getBackendAdminHost(this.$store)}/admin/agency`, requestBody, { withCredentials: true })
        .then((response) => {
          this.loading = false;
          this.uuid = response.data.uuid;
          this.$router.push(`/agency/${response.data.uuid}`);
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update(requestBody) {
      axios.put(`${getBackendAdminHost(this.$store)}/admin/agency/${this.uuid}`, requestBody, { withCredentials: true })
        .then(() => {
          this.$router.push('/agencies');
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    handleConfirmDeleteModal() {
      axios.delete(`${getBackendAdminHost(this.$store)}/admin/agency/${this.uuid}`, { withCredentials: true })
        .then(() => {
          this.$router.push('/agencies');
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    handleSave() {
      this.loading = true;

      const requestBody = this.buildRequestObject();

      if (this.isNew()) {
        this.create(requestBody);
      } else {
        this.update(requestBody);
      }
    },
    touchIgnoreableElements() {
    },
  },

};
</script>
