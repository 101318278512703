<template>
  <form v-if="!submitted">
    <page-top-area header-text="Register and create your travel website" />
    <v-row>
      <v-col
        cols="6"
      >
        <v-text-field
          v-model="domainName"
          :error-messages="domainNameErrors"
          label="Subdomain"
          placeholder="my-website"
          required
          @input="handleDomainValidation"
          @blur="handleDomainNameBlur"
        />
      </v-col>
      <v-col cols="6">
        <div v-if="domainValidation === domainValidationResultTypes.NOT_STARTED">
          <v-icon>
            mdi-question-mark
          </v-icon>
        </div>
        <div v-else-if="domainValidation === domainValidationResultTypes.CORRECT">
          <div class="text-sm-body-1 text-subtitle-1 mt-4">
            <v-icon class="mx-2">
              mdi-thumb-up
            </v-icon>
            Great! Your domain is available!
          </div>
        </div>
        <div v-else>
          <div class="text-subtitle-1 mt-4">
            <v-icon>
              mdi-alert-circle
            </v-icon>
            {{ domainValidation }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-text-field
          v-model="username"
          :error-messages="usernameErrors"
          label="Username"
          required
          @input="v$.username.$touch()"
          @blur="v$.username.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-text-field
          v-model="email"
          :error-messages="emailErrors"
          label="E-Mail"
          required
          @input="v$.email.$touch()"
          @blur="v$.email.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-text-field
          v-model="password"
          :error-messages="passwordErrors"
          label="Password (Required at least 1 char, 1 number and 1 special character. Minimum length: 8)"
          required
          type="password"
          @input="v$.password.$touch()"
          @blur="v$.password.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-text-field
          v-model="confirmPassword"
          :error-messages="confirmPasswordErrors"
          label="Confirm Password"
          required
          type="password"
          @input="v$.confirmPassword.$touch()"
          @blur="v$.confirmPassword.$touch()"
        />
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col
        cols="12"
      >
        <div class="text-caption float-left">
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>
        <v-btn
          class="float-right"
          :disabled="!v$.$dirty || v$.$error || domainValidation !== domainValidationResultTypes.CORRECT"
          :loading="loading"
          color="primary"
          size="large"
          @click="handleRegister"
        >
          Register
        </v-btn>
      </v-col>
    </v-row>
    <RequestErrorNotification :error="submitError" />
  </form>
  <v-card
    v-else
    class="p-1"
  >
    <v-container>
      <div class="text-h6 my-1">
        Thanks for registering.
      </div>
      <div class="text-body-2">
        Please check your inbox and confirm your e-mail.
        <br>
        If you don't receive an e-mail, please check your spam folder.
      </div>
    </v-container>
  </v-card>
</template>

<script>
import {
  required, maxLength, minLength, email,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { checkPasswordFormat, getErrorMessages } from '@/utils/validationUtils';
import axios from 'axios';
import { getCRMBackendHost } from '@/utils/urlUtils';
import domainValidationResultTypes from '@/types/domainValidationResultTypes';
import { debounce } from 'lodash';

export default {
  components: {},
  validations: {
    username: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(3),
    },
    email: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(3),
      email,
    },
    password: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(8),
      passwordFormat: (val) => checkPasswordFormat(val),
    },
    confirmPassword: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(8),
      sameAs: (val, siblings) => siblings.password === val,
    },
    domainName: {
      required,
      maxLength: maxLength(10),
      minLength: minLength(3),
    },
  },
  data() {
    return ({
      v$: useVuelidate(),
      loading: false,
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      domainName: '',
      submitError: null,
      submitted: false,
      domainValidationResultTypes,
      domainValidation: domainValidationResultTypes.NOT_STARTED,
    });
  },
  computed: {
    usernameErrors() {
      return getErrorMessages(this.v$.username, 'Username');
    },
    emailErrors() {
      return getErrorMessages(this.v$.email, 'E-Mail');
    },
    passwordErrors() {
      return getErrorMessages(this.v$.password, 'Password');
    },
    confirmPasswordErrors() {
      return getErrorMessages(this.v$.confirmPassword, 'Confirm Password');
    },
    domainNameErrors() {
      return getErrorMessages(this.v$.domainName, 'Subdomain Name');
    },
  },
  mounted() {
  },

  methods: {
    async handleRegister() {
      this.loading = true;
      this.submitError = null;

      await this.$recaptchaLoaded();
      const recaptchaToken = await this.$recaptcha('login');

      const requestBody = {
        username: this.username,
        email: this.email,
        password: this.password,
        recaptchaToken,
        domainName: this.domainName,
      };

      axios.post(`${getCRMBackendHost()}/user/registration`, requestBody)
        .then(() => {
          this.submitted = true;
        })
        .catch((errorResponse) => {
          if (errorResponse.status === 400 && errorResponse.data.error === 'InvaliduserDto') {
            this.submitError = JSON.parse(errorResponse.data.message)[0].defaultMessage;
          } else {
            this.submitError = 'Error during request';
          }

          this.loading = false;
        });
    },
    handleDomainValidation: debounce(function (event) {
      this.v$.domainName.$touch();
      axios.get(`${getCRMBackendHost()}/domain/check/${event.target.value}`)
        .then((response) => {
          if (!response.data.available) {
            this.domainValidation = domainValidationResultTypes.NOT_AVAILABLE;
          } else if (!response.data.correctFormat) {
            this.domainValidation = domainValidationResultTypes.WRONG_FORMAT;
          } else {
            this.domainValidation = domainValidationResultTypes.CORRECT;
          }
        })
        .catch(() => {
          this.domainValidation = domainValidationResultTypes.REQUEST_ERROR;
        });
    }, 1500),
    handleDomainNameBlur(e) {
      this.handleDomainValidation(e);
      this.v$.domainName.$touch();
    },
  },
};
</script>
