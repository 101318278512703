<template>
  <v-autocomplete
    v-model="autocompleteModel"
    item-title="name"
    :disabled="loading"
    :items="countryList"
    :error-messages="countryErrors"
    label="Country"
    :auto-select-first="true"
    :return-object="true"
    @blur="v$.countryId.$touch()"
  />
</template>

<script>

import axios from 'axios';
import { getBackendHost } from '@/utils/urlUtils';

export default {
  name: 'CountrySelection',
  props: {
    selectedValue: Number,
    countryErrors: Array,
    v$: Object,
  },
  data() {
    return {
      loading: true,
      countryList: [],
    };
  },
  computed: {
    autocompleteModel: {
      get() {
        return !this.loading && this.selectedValue && this.countryList.find((c) => c.id === this.selectedValue).name;
      },
      set(value) {
        this.v$.countryId.$touch();
        this.$emit('update:selectedValue', this.countryList.find((c) => c.name === value?.name).id);
      },
    },
  },
  mounted() {
    axios.get(`${getBackendHost(this.$store)}/country/getAllBasic`, { withCredentials: true })
      .then((response) => {
        this.countryList = response.data;
        this.loading = false;
        if (this.countryList.length === 1 && this.selectedValue === null) {
          // eslint-disable-next-line prefer-destructuring
          this.autocompleteModel = this.countryList[0];
        }
      });
  },
};
</script>
