<template>
  <v-row>
    <v-col
      :cols="$slots['button-area'] ? 4 : 11"
    >
      <div
        class="text-h6 mt-1"
        v-text="headerText"
      />
    </v-col>
    <v-col
      v-if="$slots['button-area']"
      cols="7"
    >
      <v-container fluid>
        <v-row
          justify="end"
        >
          <slot name="button-area" />
        </v-row>
      </v-container>
    </v-col>
    <v-col
      v-if="infoText != null"
      cols="1"
    >
      <v-tooltip
        :text="infoText"
        bottom
      >
        <template #activator="{ props }">
          <v-btn
            elevation="0"
            icon
            v-bind="props"
          >
            <v-icon>mdi-information-variant</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </v-col>
  </v-row>
  <v-divider class="mt-1 mb-5 mx-n12" />
</template>

<script>

export default {
  name: 'PageTopArea',
  props: {
    headerText: String,
    infoText: String,
  },
  created() {
  },
};
</script>
