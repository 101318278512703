<template>
  <div>
    <page-top-area
      header-text="Region"
      info-text="Manage all regions"
    >
      <template #button-area>
        <v-btn
          prepend-icon="mdi-plus-circle"
          variant="text"
          color="#4485ca"
          @click="handleCreate"
        >
          New region
        </v-btn>
      </template>
    </page-top-area>
    <LoaderPlaceholder
      v-if="loading"
    />
    <div v-else-if="entries.length===0">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              No regions found.
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <v-row class="pb-5">
        <v-col>
          <div class="text-body-2">
            At least 5 assignments of places are necessary to show the region as filter option for users.
          </div>
        </v-col>
      </v-row>
      <vue-good-table
        :columns="columns"
        :rows="entries"
        :pagination-options="{
          enabled: true,
          perPage: 30,
          perPageDropdown: [15, 30, 50, 100],
          dropdownAllowAll: false,
        }"
        style-class="vgt-table"
      >
        <template #table-row="props">
          <span v-if="props.column.field === 'name'">
            <router-link :to="'/region/'+props.row.uuid">
              <div class="text-subtitle-1 text-decoration-underline">
                {{ props.row.name }}
              </div>
            </router-link>
          </span>
          <span
            v-else-if="props.column.field === 'activeAssignedNumber'"
            class="m-n4"
          >
            <span :style="{color: props.row.activeAssignedNumber >=5 ? 'green' : 'red'}">
              {{ props.row.activeAssignedNumber }}
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'allowFiltering'"
            class="m-n4"
          >
            <span v-if="props.row.allowFiltering && props.row.activeAssignedNumber >=5">
              <v-icon color="green">mdi-check</v-icon>
            </span>
            <span v-else>
              <v-icon :color="props.row.activeAssignedNumber >=3 ? 'red' : 'black'">mdi-close</v-icon>
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
    <RequestErrorNotification :error="submitError" />
  </div>
</template>

<script>
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';

export default {
  components: {},
  data() {
    return {
      loading: true,
      submitError: null,
      entries: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Show as filter',
          field: 'allowFiltering',
          type: 'boolean',
        },
        {
          label: '# Assignments',
          field: 'activeAssignedNumber',
          type: 'number',
        },
      ],
    };
  },
  mounted() {
    this.axios.get(`${getBackendAdminHost(this.$store)}/admin/region`, { withCredentials: true })
      .then((response) => {
        this.entries = response.data;
        this.loading = false;
      })
      .catch(() => {
        this.submitError = 'Error during request';
      });
  },
  methods: {
    handleCreate() {
      this.$router.push('/region/new');
    },
  },
};
</script>
