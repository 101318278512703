<template>
  <LoaderPlaceholder
    v-if="loading"
  />
  <form v-else>
    <page-top-area
      :header-text="isNew() ? 'New tag' : 'Edit ' + name"
      :info-text="'Manage a tag'"
    >
      <template #button-area>
        <v-row>
          <v-col cols="6" />
          <v-col cols="6">
            <v-btn
              prepend-icon="mdi-delete"
              variant="text"
              color="#4485ca"
              @click="handleClickDelete"
            >
              Delete
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </page-top-area>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="name"
          :error-messages="nameErrors"
          :counter="10"
          label="Name"
          required
          @input="v$.name.$touch()"
          @blur="v$.name.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          v-model="type"
          item-title="name"
          :disabled="loading"
          :items="typeList"
          :error-messages="typeErrors"
          label="Type"
          :auto-select-first="true"
          :return-object="true"
          :multiple="false"
          :chips="false"
          @blur="v$.type.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-color-picker
          v-model="color"
          flat
          :modes="['hexa']"
          hide-mode-switch
          elevation="0"
        />
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col
        cols="6"
      />
      <v-col
        cols="6"
      >
        <v-btn
          :disabled="(isNew() && !v$.$dirty) || v$.$error"
          color="primary"
          @click="handleSave"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
  </form>
  <ConfirmationDialog
    :open="deleteModalOpen"
    :on-abort="handleAbortDeleteModal"
    :on-confirm="handleConfirmDeleteModal"
    :text="'Do you really want to delete?'"
  />
  <RequestErrorNotification :error="submitError" />
</template>

<script>
import {
  maxLength, minLength,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import axios from 'axios';
import { getErrorMessages } from '@/utils/validationUtils';
import { getBackendAdminHost } from '@/utils/urlUtils';
import QuillTextarea from '@/components/QuillTextarea.vue';
import PageTopArea from '@/components/PageTopArea.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { mapTagTypeId, tagTypesAsList } from '@/config/tagTypes';

export default {
  components: {
    PageTopArea,
    ConfirmationDialog,
  },
  validations: {
    name: {
      maxLength: maxLength(50),
      minLength: minLength(3),
    },
    type: { },
  },
  data() {
    return ({
      loading: !this.isNew(),
      submitError: null,
      uuid: this.isNew() ? null : this.$route.params.uuid,
      v$: useVuelidate(),
      deleteModalOpen: false,
      name: '',
      type: null,
      color: '#ffeeaa',
      typeList: tagTypesAsList(),
    });
  },
  computed: {
    nameErrors() {
      return getErrorMessages(this.v$.name, 'Name');
    },
    typeErrors() {
      return getErrorMessages(this.v$.type, 'Type');
    },
  },
  mounted() {
    this.loadBasicData();
    this.touchIgnoreableElements();
  },
  methods: {
    isNew() {
      return this.$route.params.uuid === 'new';
    },
    loadBasicData() {
      if (!this.uuid) {
        return;
      }
      axios.get(`${getBackendAdminHost(this.$store)}/admin/tag/${this.uuid}`, { withCredentials: true })
        .then((response) => {
          this.applyResponseData(response.data);
          this.loading = false;
        })
        .catch(() => {
          this.submitError = 'Error during request';
        });
    },
    applyResponseData(data) {
      this.name = data.name;
      this.type = mapTagTypeId(data.typeId);
      this.color = data.color;
    },
    handleClickDelete() {
      this.deleteModalOpen = true;
    },
    handleAbortDeleteModal() {
      this.deleteModalOpen = false;
    },
    buildRequestObject() {
      return {
        name: this.name,
        typeId: this.type.id,
        color: this.color,
      };
    },
    create(requestBody) {
      axios.post(`${getBackendAdminHost(this.$store)}/admin/tag`, requestBody, { withCredentials: true })
        .then((response) => {
          this.loading = false;
          this.uuid = response.data.uuid;
          this.$router.push(`/tag/${response.data.uuid}`);
          this.$router.push('/tags');
        })
        .catch(() => {
          this.submitError = 'Error during request';
          this.loading = false;
        });
    },
    update(requestBody) {
      axios.put(`${getBackendAdminHost(this.$store)}/admin/tag/${this.uuid}`, requestBody, { withCredentials: true })
        .then(() => {
          this.$router.push('/tags');
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    handleConfirmDeleteModal() {
      axios.delete(`${getBackendAdminHost(this.$store)}/admin/tag/${this.uuid}`, { withCredentials: true })
        .then(() => {
          this.$router.push('/tags');
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    handleSave() {
      this.loading = true;

      const requestBody = this.buildRequestObject();

      if (this.isNew()) {
        this.create(requestBody);
      } else {
        this.update(requestBody);
      }
    },
    touchIgnoreableElements() {
      this.v$.type.$touch();
    },
  },

};
</script>
