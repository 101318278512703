<template>
  <form v-if="!submitted">
    <v-row>
      <v-text-field
        v-model="password"
        :error-messages="passwordErrors"
        label="New password"
        required
        type="password"
        @input="v$.password.$touch()"
        @blur="v$.password.$touch()"
      />
    </v-row>
    <v-row>
      <v-text-field
        v-model="confirmPassword"
        :error-messages="confirmPasswordErrors"
        label="Confirm new password"
        required
        type="password"
        @input="v$.confirmPassword.$touch()"
        @blur="v$.confirmPassword.$touch()"
      />
    </v-row>
    <v-row justify="space-between">
      <v-col
        cols="12"
      >
        <v-btn
          class="float-right"
          :disabled="!v$.$dirty || v$.$error"
          :loading="loading"
          @click="handleSubmit"
        >
          Reset password
        </v-btn>
      </v-col>
    </v-row>
    <RequestErrorNotification :error="submitError" />
  </form>
  <v-card v-else>
    Password changed!
    Go to <router-link to="/login">
      Login
    </router-link>
  </v-card>
</template>

<script>
import {
  required, maxLength, minLength,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { getErrorMessages } from '@/utils/validationUtils';
import axios from 'axios';
import { getCRMBackendHost } from '@/utils/urlUtils';

export default {
  components: {
  },
  validations: {
    password: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(8),
    },
    confirmPassword: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(8),
      sameAs: (val, siblings) => siblings.password === val,
    },
  },
  data() {
    return ({
      v$: useVuelidate(),
      loading: false,
      password: '',
      confirmPassword: '',
      submitError: null,
      submitted: false,
      token: this.$route.query.token,
    });
  },
  computed: {
    passwordErrors() {
      return getErrorMessages(this.v$.password, 'Password');
    },
    confirmPasswordErrors() {
      return getErrorMessages(this.v$.confirmPassword, 'Confirm Password');
    },
  },
  mounted() {
  },

  methods: {
    async handleSubmit() {
      this.loading = true;
      this.submitError = null;

      const requestBody = {
        newPassword: this.password,
        token: this.token,
      };

      axios.post(`${getCRMBackendHost()}/user/savePassword`, requestBody)
        .then(() => {
          this.submitted = true;
        }).catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
  },
};
</script>
