<template>
  <v-app-bar
    elevation="4"
    flat
    color="#4485ca"
  >
    <v-container
      fluid
      class="d-flex pa-1"
    >
      <v-container style="max-width: 300px; ">
        <v-container style="display: flex; padding: 0; align-items: center;">
          <span>
            <slot
              v-if="!isLandingPage()"
              name="menu-hamburger-button"
            />
            <v-img
              style="margin-right: 20px"
              :width="34"
              :aspect-ratio="1"
              src="/logo.svg"
              :class="isLandingPage() ? '' :'hidden-xs'"
            />
          </span>
          <span class="hidden-xs">
            <a
              href="/"
              class="text-decoration-none text-white text-body-1 text-sm-h6"
            >
              Your Travel Itinerary
            </a>
          </span>
        </v-container>
      </v-container>
      <v-container
        v-if="isLoggedIn"
        fluid
        class="d-flex justify-end align-center"
      >
        <v-btn
          v-if="isLandingPage()"
          color="#4485ca"
          class="text-body-2"
          @click="handleDashboard"
        >
          <template #prepend>
            <v-icon color="white">
              mdi-table
            </v-icon>
          </template>
          <span class="text-decoration-none text-white">
            Dashboard
          </span>
        </v-btn>
        <div
          v-else
          class="justify-center align-center d-flex"
        >
          <v-btn
            color="#4485ca"
            class="text-body-2"
            @click="handleDomain"
          >
            <span class="text-decoration-none text-white">
              <u>{{ getCurrentDomain() }}</u>
            </span>
          </v-btn>
          <v-btn
            color="#4485ca"
            class="text-body-2"
            @click="handlePreview"
          >
            <template #prepend>
              <v-icon color="white">
                mdi-earth
              </v-icon>
            </template>
            <span class="text-decoration-none text-white">Preview</span>
          </v-btn>
          <v-btn
            color="#4485ca"
            class="text-body-2"
            @click="handleLogout"
          >
            <template #prepend>
              <v-icon color="white">
                mdi-logout-variant
              </v-icon>
            </template>
            <span class="text-decoration-none text-white">Logout</span>
          </v-btn>
        </div>
      </v-container>
      <v-container
        v-else
        fluid
        class="d-flex justify-end align-center"
      >
        <v-btn
          v-if="$route.path!=='/register'"
          color="#4485ca"
          class="text-body-2"
          @click="handleRegister"
        >
          <template #prepend>
            <v-icon color="white">
              mdi-account
            </v-icon>
          </template>
          <span class="text-decoration-none text-white">Register</span>
        </v-btn>
        <v-btn
          v-if="$route.path!=='/login'"
          color="#4485ca"
          class="text-body-2"
          @click="handleLogin"
        >
          <template #prepend>
            <v-icon color="white">
              mdi-login-variant
            </v-icon>
          </template>
          <span class="text-decoration-none text-white">Login</span>
        </v-btn>
      </v-container>
    </v-container>
  </v-app-bar>
</template>

<script>

import axios from 'axios';
import { getCRMBackendHost, getCurrentSubdomain } from '@/utils/urlUtils';

export default {
  name: 'HeaderComponent',
  data: () => ({
    //
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.loggedIn;
    },
  },
  methods: {
    getCurrentSubdomain,
    async handleLogout() {
      axios.post(`${getCRMBackendHost()}/logout`, {}, { withCredentials: true })
        .then(() => {

        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$router.push('/');
        });
      this.$store.commit('logout');
    },
    getCurrentDomain() {
      return this.$store.state.selectedDomain;
    },
    handlePreview() {
      window.open(getCurrentSubdomain(this.$store.state), '_blank');
    },
    handleDomain() {
      this.$router.push('/domains');
    },
    handleDashboard() {
      this.$router.push('/admin-overview');
    },
    handleLogin() {
      this.$router.push('/login');
    },
    handleRegister() {
      this.$router.push('/register');
    },
    isLandingPage() {
      return this.$route.path === '/';
    },
  },
};
</script>
