import PolylineUtil from 'polyline-encoded';

export const getPathFromRoute = (route) => {
  const { path } = route;
  if (path) {
    return path;
  }
  return PolylineUtil.encode([[route.startRoutePlace.lat, route.startRoutePlace.lon], [route.endRoutePlace.lat, route.endRoutePlace.lon]]);
};

export const decodePathFormat = (path) => {
  if (!path || path.includes(';') === false) {
    return [];
  }
  const waypoints = path.split(';');
  return waypoints.map((waypoint) => {
    if (waypoint.includes(',') === false) {
      return null;
    }
    const [lat, lon] = waypoint.split(',');

    return { lat: parseFloat(lat), lon: parseFloat(lon) };
  }).filter((waypoint) => waypoint !== null);
};
