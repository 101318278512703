<template>
  <v-autocomplete
    v-model="autocompleteModel"
    item-title="name"
    :disabled="loading"
    :items="geoBaseList"
    :error-messages="geoBaseErrors"
    label="Destination"
    :auto-select-first="true"
    :return-object="true"
    :readonly="readOnly"
    @blur="v$.geoBase?.$touch()"
  />
</template>

<script>

import axios from 'axios';
import { startCase } from 'lodash';
import { getBackendAdminHost, getBackendHost } from '@/utils/urlUtils';

export default {
  name: 'GeoBaseSelection',
  props: {
    selectedValue: String,
    geoBase: Array,
    v$: Object,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      geoBaseList: [],

    };
  },
  computed: {
    autocompleteModel: {
      get() {
        return (this.selectedValue != null && this.geoBaseList.find((template) => template.uuid === this.selectedValue)) || null;
      },
      set(value) {
        if (this.v$.geoBase) {
          this.v$.geoBase.$touch();
        }
        this.$emit('update:selectedValue', value.uuid);
      },
    },
  },
  mounted() {
    axios.get(`${getBackendAdminHost(this.$store)}/admin/geo-base/route-stops`, { withCredentials: true })
      .then((response) => {
        this.geoBaseList = [...response.data];
        this.loading = false;
      });
  },
};
</script>
