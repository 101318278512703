<template>
  <LoaderPlaceholder
    v-if="loading"
  />
  <form v-else>
    <v-tabs
      v-if="!isNew()"
      v-model="tab"
      class="mb-4"
      background-color="primary"
      center-active
      :on-tab-input="handleTabInput"
    >
      <v-tab
        value="general"
        :onclick="() => handleTabInput('general')"
      >
        General
      </v-tab>
      <v-tab
        value="images"
        :onclick="() => handleTabInput('images')"
      >
        <div v-if="images && images.length > 0">
          Images
        </div>
        <div
          v-else
          class="text-red"
        >
          Images
        </div>
      </v-tab>
      <v-tab
        v-if="$slots.linksTab"
        value="links"
        :onclick="() => handleTabInput('links')"
      >
        Links
      </v-tab>
      <v-tab
        value="info-cards"
        :onclick="() => handleTabInput('info-cards')"
      >
        Info Card
      </v-tab>
    </v-tabs>
    <v-window
      v-model="tab"
      class="py-2"
    >
      <v-window-item value="general">
        <page-top-area
          :header-text="isNew() ? 'New ' + geoBaseType.name : 'Edit ' + name"
          :info-text="infoTextGeneral"
        >
          <template #button-area>
            <v-row>
              <v-col
                cols="6"
                :md="4"
                :lg="3"
              >
                <div v-if="!isNew()">
                  <div v-if="active">
                    <v-btn
                      prepend-icon="mdi-eye"
                      variant="text"
                      color="#4485ca"
                      @click="handleSwitchActive"
                    >
                      Public
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-btn
                      prepend-icon="mdi-eye-off"
                      variant="text"
                      color="#4485ca"
                      @click="handleSwitchActive"
                    >
                      Hidden
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="6"
                :md="4"
                :lg="3"
              >
                <div v-if="!isNew()">
                  <div v-if="swiping">
                    <v-btn
                      prepend-icon="mdi-gesture-swipe"
                      variant="text"
                      color="#4485ca"
                      @click="handleSwitchSwiping"
                    >
                      Swiping visible
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-btn
                      prepend-icon="mdi-eye-off"
                      variant="text"
                      color="#4485ca"
                      @click="handleSwitchSwiping"
                    >
                      Swiping hidden
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="6"
                :md="4"
                :lg="3"
              >
                <v-btn
                  prepend-icon="mdi-format-color-fill"
                  variant="text"
                  color="#4485ca"
                  :loading="loadingFillAi"
                  @click="handleClickFillAI"
                >
                  Fill AI
                </v-btn>
              </v-col>
              <v-col
                v-if="!isNew()"
                cols="6"
                :md="4"
                :lg="3"
              >
                <v-btn
                  prepend-icon="mdi-delete"
                  variant="text"
                  color="#4485ca"
                  @click="handleClickDelete"
                >
                  Delete
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </page-top-area>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="name"
              :error-messages="nameErrors"
              :counter="10"
              label="Name"
              required
              @input="v$.name.$touch()"
              @blur="v$.name.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <CountrySelection
              v-model:selected-value="countryId"
              :country-errors="countryErrors"
              :v$="v$"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="teaser"
              auto-grow
              placeholder="Short teaser for swiping cards"
            />
            <v-btn
              class="float-right"
              variant="text"
              color="#4485ca"
              @click="()=> handleOpenWizard(requestTypes.TEASER, (value) => teaser = value)"
            >
              Use wizard
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <QuillTextarea
              v-if="!loading"
              v-model:content="description"
              placeholder="Detailed description"
            />
            <v-btn
              class="float-right"
              variant="text"
              color="#4485ca"
              @click="()=> handleOpenWizard(requestTypes.DESCRIPTION, (value) => description = value)"
            >
              Use wizard
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <RegionSelection
              v-model:selected-values="regions"
              :v$="v$"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <TagSelection
              v-model:selected-values="tags"
              :v$="v$"
            />
          </v-col>
        </v-row>
        <v-row justify="flex-start">
          <v-col class="d-flex align-center">
            <span class="grey--text text--lighten-2 mr-2">
              Rating
              <span v-if="rating!=null">
                ({{ rating }})
              </span>
              :
            </span>
            <v-rating
              v-model="rating"
              :error-messages="ratingErrors"
              label="Rating"
              required
              size="32"
              hover="true"
              @input="v$.rating.$touch()"
              @blur="v$.rating.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="stayDuration"
              :items="stayDurationItems"
              :item-value="item => item.value"
              :item-title="item => item.label"
              label="Stay Duration"
              return-object
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="9">
            <GeoCoordinateInput
              v-model:selected-lat="lat"
              v-model:selected-lon="lon"
              :v$="v$"
              :lat-errors="latErrors"
              :lon-errors="lonErrors"
            />
          </v-col>
          <v-col cols="3">
            <v-btn
              class="float-right"
              variant="text"
              color="#4485ca"
              @click="handleGetGeoCoordinates"
            >
              From name/country
            </v-btn>
            <v-btn
              class="float-right"
              variant="text"
              color="#4485ca"
              @click="handleOpenGeoCoordinates"
            >
              Open map
            </v-btn>
          </v-col>
        </v-row>
        <slot name="additionalFields" />
        <v-row justify="space-between">
          <v-col
            cols="6"
          >
            <v-btn
              color="#ddd"
              class="float-right mr-2"
              @click="handleCancel"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col
            cols="6"
          >
            <v-btn
              :disabled="saveDisabled()"
              color="primary"
              prepend-icon="mdi-content-save"
              class="text-subtitle-1"
              @click="handleSave"
            >
              <span v-if="isNew()">
                Create new {{ geoBaseType.name }}
              </span>
              <span v-else>
                Save changes
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-window-item>

      <v-window-item
        v-if="!isNew()"
        value="images"
      >
        <page-top-area
          header-text="Order & select Images"
          :info-text="'Manage your ' + geoBaseType.name"
        >
          <template #button-area>
            <v-row>
              <v-col class="mx-1">
                <a
                  :href="getWikimediaLink()"
                  target="_blank"
                  class="text-caption"
                >Wikimedia</a>
              </v-col>
              <v-col class="mx-1">
                <a
                  :href="getUnsplashLink()"
                  target="_blank"
                  class="text-caption"
                >Unsplash</a>
              </v-col>
              <v-col class="mx-1">
                <a
                  :href="getPexelsLink()"
                  target="_blank"
                  class="text-caption"
                >Pexels</a>
              </v-col>
              <v-col class="mx-1">
                <a
                  :href="getPixabayLink()"
                  target="_blank"
                  class="text-caption"
                >Pixabay</a>
              </v-col>
            </v-row>
          </template>
        </page-top-area>
        <v-row v-if="!isNew()">
          <v-col col="12">
            <ImageUpload
              :images="images"
              :upload-path="imageUploadPath"
              :reload-function="loadImages"
            />
          </v-col>
        </v-row>
      </v-window-item>

      <v-window-item
        v-if="$slots.linksTab"
        value="links"
      >
        <slot name="linksTab" />
      </v-window-item>

      <v-window-item value="info-cards">
        <v-row>
          <v-col>
            <v-btn
              prepend-icon="mdi-plus-circle"
              variant="text"
              color="#4485ca"
              @click="()=>handleCreateInfoCard(null)"
            >
              New info card
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="infoCardTemplate in infoCardTemplateList"
            :key="infoCardTemplate.uuid"
            cols="12"
          >
            <v-btn
              prepend-icon="mdi-plus-circle"
              variant="text"
              color="#4485ca"
              @click="() => handleCreateInfoCard(infoCardTemplate.uuid)"
            >
              {{ infoCardTemplate.question }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <vue-good-table
              :columns="infoCardColumns"
              :rows="infoCards"
              :pagination-options="{
                enabled: false
              }"
              style-class="vgt-table"
            >
              <template #table-row="props">
                <span v-if="props.column.field === 'question'">
                  <router-link :to="'/info-card/'+geoBaseType.path+'/'+uuid+'/'+props.row.uuid">
                    <div
                      :class="'text-subtitle-1 text-decoration-underline '+(props.row.infoCardTemplate!==null ? 'font-italic' : '')"
                    >
                      {{ props.row.question }}
                    </div>
                  </router-link>
                </span>
                <span v-else-if="props.column.field === 'insertTime'">
                  {{ formatDate(props.row.insertTime) }}
                </span>
                <span
                  v-else-if="props.column.field === 'active'"
                  class="m-n4"
                >
                  <v-checkbox-btn
                    v-model="props.row.active"
                    density="compact"
                    @click.stop="handleInfoCardSwitchActive($event, props.row.uuid)"
                  />
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
  </form>
  <ConfirmationDialog
    :open="deleteModalOpen"
    :on-abort="handleAbortDeleteModal"
    :on-confirm="handleConfirmDeleteModal"
    :text="'Do you really want to delete?'"
  />
  <AiWizardTextDialog
    :open="wizardModalOpen"
    :on-abort="handleCloseWizard"
    :on-apply="handleApplyWizardContent"
    :initial-entity-name="name"
    :initial-request-type="wizardRequestType"
    :geo-base-type="geoBaseType"
    :country-id="countryId"
  />
  <RequestErrorNotification :error="submitError" />
</template>

<script>
import {
  maxLength, minLength,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { getErrorMessages } from '@/utils/validationUtils';
import axios from 'axios';
import CountrySelection from '@/components/CountrySelection.vue';
import TagSelection from '@/components/TagSelection.vue';
import RegionSelection from '@/components/RegionSelection.vue';
import ImageUpload from '@/components/ImageUpload.vue';
import { getBackendAdminHost } from '@/utils/urlUtils';
import QuillTextarea from '@/components/QuillTextarea.vue';
import GeoCoordinateInput from '@/components/GeoCoordinateInput.vue';
import PageTopArea from '@/components/PageTopArea.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import AiWizardTextDialog from '@/components/AiWizardTextDialog.vue';
import { textWizardRequestTypes } from '@/config/textWizardRequestTypes';
import { tr } from 'vuetify/locale';

export default {
  components: {
    GeoCoordinateInput,
    CountrySelection,
    TagSelection,
    ImageUpload,
    QuillTextarea,
    PageTopArea,
    ConfirmationDialog,
    RegionSelection,
    AiWizardTextDialog,
  },
  props: {
    geoBaseType: {
      type: Object,
      required: true,
    },
    infoTextGeneral: {
      type: String,
      required: true,
    },
    additionalFields: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  validations: {
    name: {
      maxLength: maxLength(50),
      minLength: minLength(3),
    },
    // description: { maxLength: maxLength(8048) }, // Todo: error else. Dirty not working here, :(
    // teaser: { maxLength: maxLength(1024) }, // Todo: error else. Dirty not working here, :(
    countryId: {},
    tags: {},
    regions: {},
    rating: {},
    stayDuration: {},
    lat: {},
    lon: {},
  },
  data() {
    return ({
      loading: !this.isNew(),
      submitError: null,
      uuid: this.isNew() ? null : this.$route.params.uuid,
      v$: useVuelidate(),
      deleteModalOpen: false,
      name: '',
      description: '',
      teaser: '',
      countryId: null,
      tags: [],
      regions: [],
      imageUploadPath: null,
      rating: null,
      images: [],
      active: false,
      swiping: false,
      requestTypes: textWizardRequestTypes,
      wizardModalOpen: false,
      wizardRequestType: null,
      disableUnsavedWarning: false,
      stayDuration: {
        value: 48,
        label: '2 nights',
        initial: true,
      },
      stayDurationItems: [
        {
          value: 0,
          label: 'No stay',
        },
        {
          value: 1,
          label: '1 hour',
        },
        {
          value: 2,
          label: '2 hours',
        },
        {
          value: 3,
          label: '3 hours',
        },
        {
          value: 6,
          label: '6 hours',
        },
        {
          value: 12,
          label: '1/2 day',
        },
        {
          value: 24,
          label: '1 night',
        },
        {
          value: 48,
          label: '2 nights',
        },
        {
          value: 72,
          label: '3 nights',
        },
        {
          value: 96,
          label: '4 nights',
        },
        {
          value: 120,
          label: '5 nights',
        },
        {
          value: 144,
          label: '6 nights',
        },
        {
          value: 168,
          label: '7 nights',
        },
        {
          value: 192,
          label: '8 nights',
        },
      ],
      infoCardColumns: [
        {
          label: 'Question',
          field: 'question',
        }, {
          label: 'Author',
          field: 'authorName',
        },
        {
          label: 'Time',
          field: 'insertTime',
        },
        {
          label: 'Active',
          field: 'active',
        },
      ],
      infoCards: [],
      infoCardTemplateList: [],
      // For new:
      lat: null,
      lon: null,
      tab: window.location.hash ? window.location.hash.replace('#', '') : '',
      previousTab: window.location.hash ? window.location.hash.replace('#', '') : '',
      loadingFillAi: false,
      initialData: {},
    });
  },
  computed: {
    tr() {
      return tr;
    },
    nameErrors() {
      return getErrorMessages(this.v$.name, 'Name');
    },
    descriptionErrors() {
      return getErrorMessages(this.v$.description, 'Description');
    },
    teaserErrors() {
      return getErrorMessages(this.v$.teaser, 'Teaser');
    },
    countryErrors() {
      return getErrorMessages(this.v$.countryId, 'Country');
    },
    ratingErrors() {
      return getErrorMessages(this.v$.rating, 'Rating');
    },
    stayDurationErrors() {
      return getErrorMessages(this.v$.stayDuration, 'Stay Duration');
    },
    latErrors() {
      return getErrorMessages(this.v$.lat, 'Latitude');
    },
    lonErrors() {
      return getErrorMessages(this.v$.lon, 'Longitude');
    },
    isDirty() {
      const currentData = {
        name: this.name,
        description: this.description,
        teaser: this.teaser,
        countryId: this.countryId,
        rating: this.rating,
        stayDuration: this.stayDuration,
        lat: this.lat,
        lon: this.lon,
        tags: this.tags,
        regions: this.regions,
        active: this.active,
        swiping: this.swiping,
      };
      return JSON.stringify(currentData) !== JSON.stringify(this.initialData);
    },
  },
  mounted() {
    this.loadBasicData();
    this.loadInfoCards();
    this.loadImages();
    this.touchIgnoreableElements();
  },
  methods: {
    beforeRouteLeave(to, from, next) {
      if (!this.disableUnsavedWarning && this.isDirty) {
        const answer = window.confirm('You have unsaved changes. Are you sure you want to leave without saving?');
        if (answer) {
          next();
        } else {
          next(false);
        }
      } else {
        next();
      }
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleString();
    },
    isNew() {
      return this.$route.params.uuid === 'new';
    },
    loadBasicData() {
      if (!this.uuid) {
        return;
      }
      axios.get(`${getBackendAdminHost(this.$store)}/admin/${this.geoBaseType.path}/${this.uuid}`, { withCredentials: true })
        .then((response) => {
          this.applyResponseData(response.data);
          this.loadInfoCardTemplates();
        })
        .catch(() => {
          this.submitError = 'Error during request';
        });
    },
    loadInfoCards() {
      if (!this.uuid) {
        return;
      }
      axios.get(`${getBackendAdminHost(this.$store)}/admin/info-card/by-geo-base/${this.uuid}`, { withCredentials: true })
        .then((response) => {
          this.infoCards = response.data;
          this.loadInfoCardTemplates();
        })
        .catch(() => {
          this.submitError = 'Error during request';
        });
    },
    handleCreateInfoCard(infoCardTemplateUuid) {
      this.$router.push(`/info-card/${this.geoBaseType.path}/${this.uuid}/new?infoCardTemplateUuid=${infoCardTemplateUuid || ''}`);
    },
    handleInfoCardSwitchActive(event, infoCardUuid) {
      event.preventDefault();
      axios.post(`${getBackendAdminHost(this.$store)}/admin/info-card/${infoCardUuid}/switch-active`, {}, { withCredentials: true })
        .then(() => {
          const changedEntry = this.infoCards.find((d) => d.uuid === infoCardUuid);
          changedEntry.active = !changedEntry.active;
          this.infoCards = [...this.infoCards]; // update
        })
        .catch(() => {
          this.submitError = 'Error switching active state';
        });
    },
    loadImages() {
      if (!this.uuid) {
        return;
      }
      axios.get(`${getBackendAdminHost(this.$store)}/admin/image/geo/${this.uuid}`, { withCredentials: true })
        .then((response) => {
          const responseImages = response.data.map((image) => ({
            path: image.path,
            uuid: image.uuid,
            order: image.order,
            active: image.active,
            copyright: image.copyright,
          }));
          responseImages.sort((a, b) => (a.order !== null ? a.order : 999) - (b.order !== null ? b.order : 999));
          this.images = [...responseImages];
          this.loading = false;
        })
        .catch(() => {
          this.$router.push('/login');
        });
    },
    applyResponseData(data) {
      this.name = data.name;
      this.imageUploadPath = `${getBackendAdminHost(this.$store)}/admin/image/upload/geo/${this.uuid}`;
      this.description = data.description;
      this.teaser = data.teaser;
      this.countryId = data.countryId;
      this.rating = data.rating;
      this.stayDuration = this.stayDurationItems.find((s) => s.value === data.stayDuration);
      this.lat = data.lat;
      this.lon = data.lon;
      this.modified = data.modified;
      this.tags = [...data.tags.map((t) => t.uuid)];
      this.regions = [...data.regions.map((r) => r.uuid)];
      this.active = data.active;
      this.swiping = data.swiping;
      this.additionalFields.forEach((field) => {
        field.callbackLoaded(data[field.name]);
      });
      // Set initialData after loading
      this.initialData = JSON.parse(JSON.stringify({
        name: this.name,
        description: this.description,
        teaser: this.teaser,
        countryId: this.countryId,
        rating: this.rating,
        stayDuration: this.stayDuration,
        lat: this.lat,
        lon: this.lon,
        tags: this.tags,
        regions: this.regions,
        active: this.active,
        swiping: this.swiping,

      }));
    },
    handleSwitchActive() {
      axios.post(`${getBackendAdminHost(this.$store)}/admin/${this.geoBaseType.path}/${this.uuid}/switch-active`, {}, { withCredentials: true })
        .then(
          () => {
            this.active = !this.active;
          },
        )
        .catch(() => {
          this.submitError = 'Error - maybe it doesn\'t have enough data';
        });
    },
    handleSwitchSwiping() {
      axios.post(`${getBackendAdminHost(this.$store)}/admin/${this.geoBaseType.path}/${this.uuid}/switch-swiping`, {}, { withCredentials: true })
        .then(
          () => {
            this.swiping = !this.swiping;
          },
        )
        .catch(() => {
          this.submitError = 'Error';
        });
    },
    buildRequestObjectMissingData() {
      const missingFields = [];
      if (!this.teaser) {
        missingFields.push('teaser');
      }
      if (!this.description || !this.description.replaceAll('<p>', '')
        .replaceAll('</p>', '')
        .replaceAll('<br>', '')
        .trim().length) {
        missingFields.push('description');
      }
      if (!this.rating) {
        missingFields.push('rating');
      }
      if (!this.stayDuration || this.stayDuration.initial) {
        missingFields.push('stayDuration');
      }
      if (!this.lat || !this.lon) {
        missingFields.push('geoCoordinates');
      }
      if (!this.regions || this.regions.length === 0) {
        missingFields.push('regions');
      }
      if (!this.tags || this.tags.length === 0) {
        missingFields.push('tags');
      }

      return {
        countryId: this.countryId,
        name: this.name,
        missingFields,
      };
    },
    handleClickFillAI() {
      if (!this.name || !this.countryId) {
        this.submitError = 'Please insert name and select country first';
        return;
      }

      const requestObjectMissingData = this.buildRequestObjectMissingData();
      if (requestObjectMissingData.missingFields.length === 0) {
        this.submitError = 'No empty data to fill out.';
        return;
      }

      this.loadingFillAi = true;
      this.submitError = 'Requesting AI data. It can take several seconds...';
      axios.post(`${getBackendAdminHost(this.$store)}/admin/ai-wizard/get-missing-data`, requestObjectMissingData, { withCredentials: true })
        .then(
          (response) => {
            const { data } = response;
            if (data.teaser) {
              this.teaser = data.teaser;
            }
            if (data.description) {
              this.description = `<p>${data.description}</p>`;
            }
            if (data.rating) {
              this.rating = data.rating;
            }
            if (data.stayDuration) {
              this.stayDuration = this.stayDurationItems.find((s) => s.value === data.stayDuration);
            }
            if (data.lat) {
              this.lat = data.lat;
              this.v$.lat.$touch();
            }
            if (data.lon) {
              this.lon = data.lon;
              this.v$.lon.$touch();
            }
            if (data.regions) {
              this.regions = data.regions;
            }
            if (data.tags) {
              this.tags = data.tags;
            }
            this.submitError = null;
            this.touchIgnoreableElements();
          },
        )
        .catch(() => {
          this.submitError = 'Error';
        })
        .finally(() => {
          this.loadingFillAi = false;
        });
    },
    handleClickDelete() {
      this.deleteModalOpen = true;
    },
    handleAbortDeleteModal() {
      this.deleteModalOpen = false;
    },
    handleOpenWizard(requestType, handleApply) {
      this.wizardRequestType = requestType;
      this.handleApplyWizardContent = handleApply;
      this.wizardModalOpen = true;
    },
    handleCloseWizard() {
      this.wizardModalOpen = false;
    },
    handleApplyWizard(newContent) {
      // this.description = `${this.description.trim()}
      this.wizardModalOpen = false;
    },
    buildRequestObject() {
      const additionalFieldsRequest = this.additionalFields.reduce((obj, field) => ({
        ...obj,
        [field.name]: field.value,
      }), {});

      return {
        name: this.name,
        description: this.description,
        teaser: this.teaser,
        rating: this.rating,
        stayDuration: this.stayDuration.value,
        countryId: this.countryId,
        tags: this.tags,
        regions: this.regions,
        active: this.active,
        lat: this.lat,
        lon: this.lon,
        ...additionalFieldsRequest,
      };
    },
    create(requestBody) {
      axios.post(`${getBackendAdminHost(this.$store)}/admin/${this.geoBaseType.path}`, requestBody, { withCredentials: true })
        .then((response) => {
          this.loading = false;
          this.uuid = response.data.uuid;
          this.disableUnsavedWarning = true;
          this.$router.push(`/${this.geoBaseType.path}/${response.data.uuid}`);
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update(requestBody) {
      axios.put(`${getBackendAdminHost(this.$store)}/admin/${this.geoBaseType.path}/${this.uuid}`, requestBody, { withCredentials: true })
        .then(() => {
          this.disableUnsavedWarning = true;
          this.goBackToOverview();
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    handleConfirmDeleteModal() {
      axios.delete(`${getBackendAdminHost(this.$store)}/admin/${this.geoBaseType.path}/${this.uuid}`, { withCredentials: true })
        .then(() => {
          this.goBackToOverview();
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    handleSave() {
      this.loading = true;

      const requestBody = this.buildRequestObject();

      if (this.isNew()) {
        this.create(requestBody);
      } else {
        this.update(requestBody);
      }
    },
    handleCancel() {
      this.goBackToOverview();
    },
    goBackToOverview() {
      this.$router.push(`/${this.geoBaseType.path}s`);
    },
    handleOpenGeoCoordinates() {
      const url = `https://www.google.com/maps/place/${this.lat.toString()
        .replace(',', '.')}+,${this.lon.toString()
        .replace(',', '.')}/@${this.lat.toString()
        .replace(',', '.')},,${this.lon.toString()
        .replace(',', '.')},19z/`;
      window.open(url, '_blank');
    },
    handleGetGeoCoordinates() {
      if (!this.name || !this.countryId) {
        this.submitError = 'Please insert name and select country first';
        return;
      }

      const requestBody = {
        slug: this.name,
        countryId: this.countryId,
      };

      axios.post(`${getBackendAdminHost(this.$store)}/admin/geo-coordinates`, requestBody, { withCredentials: true })
        .then((response) => {
          this.lat = response.data.lat;
          this.lon = response.data.lon;

          this.v$.lat.$touch();
          this.v$.lon.$touch();

          this.submitError = `Loaded coordinates for '${response.data.details}'`;
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    loadInfoCardTemplates() {
      if (!this.uuid) {
        return;
      }
      this.loading = true;
      axios.get(`${getBackendAdminHost(this.$store)}/admin/info-card-template-link/by-geo-base-uuid/${this.uuid}`, { withCredentials: true })
        .then((response) => {
          this.infoCardTemplateList = [...response.data.filter((template) => !this.infoCards.find((card) => card.infoCardTemplate?.uuid === template.uuid))];
          this.loading = false;
        });
    },
    touchIgnoreableElements() {
      this.v$.regions.$touch();
      this.v$.tags.$touch();
      this.v$.rating.$touch();
      this.v$.stayDuration.$touch();
    },
    saveDisabled() {
      return ((this.isNew() && !this.v$.$dirty) || this.v$.$error)
        || this.additionalFields.find((field) => !field.requireConditionFit()) !== undefined;
    },
    getWikimediaLink() {
      return `https://commons.wikimedia.org/w/index.php?search=${encodeURIComponent(this.name)}`;
    },
    getUnsplashLink() {
      return `https://unsplash.com/s/photos/${encodeURIComponent(this.name)}?license=free`;
    },
    getPexelsLink() {
      return `https://www.pexels.com/search/${encodeURIComponent(this.name)}`;
    },
    getPixabayLink() {
      return `https://pixabay.com/images/search/${encodeURIComponent(this.name)}`;
    },
    handleTabInput(newTab) {
      if (this.isDirty) {
        const answer = window.confirm('You have unsaved changes. Are you sure you want to switch tabs without saving?');
        if (answer) {
          this.previousTab = newTab;
          this.tab = newTab;
          window.location.hash = `#${newTab}`;
        } else {
          this.tab = this.previousTab;
        }
      } else {
        this.previousTab = newTab;
        this.tab = newTab;
        window.location.hash = `#${newTab}`;
      }
    },
  },
};
</script>
