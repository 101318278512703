export const getBackendHost = (store) => `${process?.env?.VUE_APP_BACKEND_HOST || '/api/backend-pi'}/${store.state.selectedDomain}`;
// export const getBackendAdminHost = () => 'http://localhost:62737';
export const getBackendAdminHost = (store) => `${process?.env?.VUE_APP_BACKEND_ADMIN_HOST || '/api/core'}/${store.state.selectedDomain}`;
export const getCRMBackendHost = () => `${process?.env?.VUE_APP_BACKEND_CRM_HOST || '/api'}`;

export const getCurrentSubdomain = (storeState) => {
  const newSubdomain = storeState.selectedDomain;
  const currentProtocol = window.location.protocol;
  const currentHostname = window.location.hostname;
  // return `${currentProtocol}//${newSubdomain}.${currentHostname}`;
  return `${currentProtocol}//${currentHostname}/y/s/${newSubdomain}`;
};
