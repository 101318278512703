<template>
  <form v-if="!submitted">
    <page-top-area header-text="Reset password" />
    <v-row>
      <v-col
        cols="12"
      >
        <div class="text-body-1">
          Please enter your email address. You will receive a link to create a new password via email.
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-text-field
        v-model="email"
        :error-messages="emailErrors"
        label="E-Mail"
        required
        @input="v$.email.$touch()"
        @blur="v$.email.$touch()"
      />
    </v-row>
    <v-row justify="space-between">
      <v-col
        cols="12"
      >
        <v-btn
          class="float-right"
          :disabled="!v$.$dirty || v$.$error"
          :loading="loading"
          color="primary"
          @click="handleSubmit"
        >
          Reset password
        </v-btn>
      </v-col>
    </v-row>
    <RequestErrorNotification :error="submitError" />
  </form>
  <v-card v-else>
    Password reset successfully! Please check your email.
  </v-card>
</template>

<script>
import {
  required, maxLength, minLength,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { getErrorMessages } from '@/utils/validationUtils';
import axios from 'axios';
import { getCRMBackendHost } from '@/utils/urlUtils';

export default {
  components: {
  },
  validations: {
    email: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(3),
    },
  },
  data() {
    return ({
      v$: useVuelidate(),
      loading: false,
      email: '',
      submitError: null,
      submitted: false,
    });
  },
  computed: {
    emailErrors() {
      return getErrorMessages(this.v$.email, 'E-Mail');
    },
  },
  mounted() {
  },

  methods: {
    async handleSubmit() {
      this.loading = true;
      this.submitError = null;

      await this.$recaptchaLoaded();
      const recaptchaToken = await this.$recaptcha('reset');

      const requestBody = {
        email: this.email,
        recaptchaToken,
      };

      axios.post(`${getCRMBackendHost()}/user/resetPassword`, requestBody, { withCredentials: true })
        .then(() => {
          this.submitted = true;
        }).catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
  },
};
</script>
