<template>
  <v-autocomplete
    v-model="autocompleteModel"
    item-title="name"
    :disabled="loading"
    :items="tagList"
    :error-messages="tagErrors"
    label="Tags"
    :auto-select-first="true"
    :return-object="true"
    :multiple="true"
    :chips="true"
    @blur="v$.tags?.$touch()"
  />
</template>

<script>

import axios from 'axios';
import { startCase } from 'lodash';
import { getBackendHost } from '@/utils/urlUtils';

export default {
  name: 'TagSelection',
  props: {
    selectedValues: Array,
    tagErrors: Array,
    v$: Object,
  },
  data() {
    return {
      loading: true,
      tagList: [],
    };
  },
  computed: {
    autocompleteModel: {
      get() {
        return !this.loading && this.selectedValues.map((selectedValue) => this.tagList.find((tag) => tag.uuid === selectedValue)).filter((selectedValue) => selectedValue);
      },
      set(value) {
        if (this.v$.tags) {
          this.v$.tags.$touch();
        }
        this.$emit('update:selectedValues', value.map((v) => this.tagList.find((tag) => (v.name && v.name.toLowerCase() === tag.name.toLowerCase())
          || (typeof v === 'string' && tag.name.toLowerCase() === v.toLowerCase())).uuid));
      },
    },
  },
  mounted() {
    axios.get(`${getBackendHost(this.$store)}/tags/getAllBasic`, { withCredentials: true })
      .then((response) => {
        this.tagList = response.data.map((tag) => ({ ...tag, name: startCase(tag.name) }));
        this.loading = false;
      });
  },
};
</script>
