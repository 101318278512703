<template>
  <v-parallax
    dark
    src="/background.jpg"
    height="430"
    scale="0.9"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="10">
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            md="6"
            xl="8"
          >
            <h1 class="display-2 font-weight-bold mb-4 mt-8 ">
              White Label Travel Itinerary Planner
            </h1>
            <h2 class="font-weight-light  ">
              Create a personalized, fully branded travel platform with ease. <br>
              Offer your customers a seamless travel planning experience.
            </h2>
            <v-btn
              rounded
              outlined
              large
              dark
              class="mt-5"
              :href="examples[0].url"
              target="_blank"
              color="primary"
            >
              Open Example
              <v-icon class="ml-2">
                mdi-rocket-launch
              </v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="6"
            xl="4"
            class="hidden-sm-and-down"
          />
        </v-row>
      </v-col>
    </v-row>
    <div class="svg-border-waves text-white">
      <v-img src="" />
    </div>
  </v-parallax>
  <v-container
    id="features"
    fluid
    class="mt-2"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="10"
      >
        <v-row
          align="center"
          justify="space-around"
        >
          <v-col
            v-for="(feature, i) in features"
            :key="i"
            cols="12"
            sm="4"
            class="text-center"
          >
            <v-hover>
              <template #default="{ isHovering, props }">
                <v-card
                  v-bind="props"
                  class="card"
                  shaped
                  :elevation="isHovering ? 10 : 4"
                  :class="{ up: isHovering }"
                >
                  <v-img
                    :src="feature.img"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-effect': isHovering }"
                  />
                  <h2 class="font-weight-regular">
                    {{ feature.title }}
                  </h2>
                  <h4 class="font-weight-regular subtitle-1">
                    {{ feature.text }}
                  </h4>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
  <section id="examples">
    <v-container fluid>
      <v-row
        align="center"
        justify="center"
      />
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="10"
        >
          <h3 class="font-weight-light display-2 mt-4">
            References:
          </h3>
        </v-col>
        <v-col
          cols="12"
          sm="10"
        >
          <v-row>
            <v-col
              v-for="example in examples"
              :key="example.name"
              cols="12"
              sm="9"
              class="white--text text-left"
            >
              <a
                :href="example.url"
                target="_blank"
              >
                <h1 class="font-weight-light display-2 mb-2">{{ example.name }}</h1>
              </a>
              <h1 class="font-weight-light display-3 mb-2 text-subtitle-2">
                {{ example.subTitle }}
              </h1>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
  <section id="about">
    <v-container fluid>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="10"
        >
          <v-row
            align="center"
            justify="center"
          >
            <v-col
              cols="12"
              md="12"
            >
              <h1 class="font-weight-light display-2 mb-6 mt-2">
                All in one
              </h1>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex align-center"
                >
                  <!--                  <v-img-->
                  <!--                    src=""-->
                  <!--                    max-width="60px"-->
                  <!--                    class="mr-4"-->
                  <!--                  />-->
                  <v-icon class="mr-4">
                    mdi-map-marker
                  </v-icon>
                  <p class="text-justify">
                    <b> Destinations:</b> Add places to visit, with descriptions, images, and
                    ratings, so users can easily find the best spots based on your recommendations.
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex align-center"
                >
                  <!--                  <v-img-->
                  <!--                    src=""-->
                  <!--                    max-width="60px"-->
                  <!--                    class="mr-4"-->
                  <!--                  />-->
                  <v-icon class="mr-4">
                    mdi-transfer
                  </v-icon>
                  <p class="text-justify">
                    <b>Transfers:</b> Include precise transportation options such as buses and
                    trains. Provide users with intelligent suggestions on combining travel options.
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex align-center"
                >
                  <!--                  <v-img-->
                  <!--                    src=""-->
                  <!--                    max-width="60px"-->
                  <!--                    class="mr-4"-->
                  <!--                  />-->
                  <v-icon class="mr-4">
                    mdi-database
                  </v-icon>
                  <p class="text-justify">
                    <b>Fully customizable:</b> Personalize every element to ensure your platform
                    reflects your brand and style.
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <!--            <v-col cols="12" md="5" class="d-none d-md-flex">-->
            <!--              <v-img-->
            <!--                src="https://images.pexels.com/photos/1051075/pexels-photo-1051075.jpeg"-->
            <!--                class="d-block ml-auto mr-0"-->
            <!--                max-width="250px"-->
            <!--              />-->
            <!--            </v-col>-->
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
  <section class="my-10">
    <v-container fluid>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="10"
        >
          <h1 class="font-weight-light display-1">
            Contact us
          </h1>
          <h3 class="font-weight-light mt-3">
            Have any questions? Feel free to reach out.
          </h3>
          <h3 class="font-weight-light mt-3">
            Email address:<b> mail /at/ yourtravelitinerary.com</b>
          </h3>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      features: [
        {
          img: '',
          title: 'Your travel platform',
          text: 'Quickly set up information on destinations, regions, and transfers to generate a complete, digital travel guide for your clients.',
        },
        {
          img: '',
          title: 'Unique Itinerary Planner',
          text: 'Empower your customers to craft their own travel experiences using an AI-powered, user-friendly itinerary planner.',
        },
        {
          img: '',
          title: 'Easy to use',
          text: 'No technical skills required! Simply enter your content and customize the design and features to match your brand.',
        },
      ],
      examples: [
        // {
        //   name: 'Jordan Trip Planner',
        //   subTitle: 'Plan your trip in Jordan with the best destinations, hotels, and transport options.',
        //   url: '/y/s/jordan',
        // },
        {
          name: 'China Trip Planner',
          subTitle: 'Create your own custom itinerary for your trip to China.',
          url: '/y/s/china',
        },
      ],
    };
  },
};
</script>

<style>
.card {
  min-height: 110px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}
</style>
