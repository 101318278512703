<template>
  <v-dialog
    v-model="modalOpen"
    class="confirmation-dialog"
  >
    <v-card>
      <v-card-text>
        {{ text }}
      </v-card-text>
      <v-card-actions>
        <v-row justify="space-between">
          <v-col
            cols="6"
          >
            <v-btn
              color="primary"
              @click="onConfirm"
            >
              Confirm
            </v-btn>
          </v-col>
          <v-col
            cols="6"
          >
            <v-btn
              class="float-right"
              @click="onAbort"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ConfirmationDialog',
  props: {
    open: Boolean,
    text: String,
    onAbort: Function,
    onConfirm: Function,
  },
  data() {
    return {};
  },
  computed: {
    modalOpen: {
      get() {
        return this.open;
      },
      set() {
      },
    },
  },
  created() {
  },
};
</script>

<style scoped>
  .confirmation-dialog {
    max-width: 400px;
  }
</style>
