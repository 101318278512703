<template>
  <div>
    <page-top-area
      header-text="Way"
      info-text="Manage all ways"
    >
      <template #button-area>
        <v-btn
          prepend-icon="mdi-plus-circle"
          variant="text"
          color="#4485ca"
          @click="handleCreate"
        >
          New way
        </v-btn>
      </template>
    </page-top-area>
    <GeoEntityListView :geo-base-type="geoBaseType" />
  </div>
</template>

<script>

import GeoEntityListView from '@/views/GeoEntityListView.vue';
import PageTopArea from '@/components/PageTopArea.vue';
import { geoBaseTypes } from '@/config/geoBaseTypes';

export default {
  components: { PageTopArea, GeoEntityListView },
  data() {
    return {
      geoBaseType: geoBaseTypes.WAY,
    };
  },
  methods: {
    handleCreate() {
      this.$router.push('/way/new');
    },
  },
};
</script>
