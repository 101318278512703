<template>
  <form v-if="!loading">
    <v-row>
      <QuillTextarea v-model:content="content" />
    </v-row>
    <v-row v-if="canAddItineraryCollection">
      <v-col>
        <v-select
          v-model="selectedItinerariesCollections"
          label="Linked itineraries collections"
          :items="itinerariesCollections"
          :item-value="item => item.uuid"
          :item-title="item => item.name"
          multiple
        />
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col
        cols="12"
      >
        <v-btn
          class="float-right"
          :disabled="v$.$error"
          :loading="loading"
          @click="handleSave"
        >
          Save Page
        </v-btn>
      </v-col>
    </v-row>
    <RequestErrorNotification :error="submitError" />
  </form>
</template>

<script>
import {
  required, maxLength, minLength,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { getErrorMessages } from '@/utils/validationUtils';
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import QuillTextarea from '@/components/QuillTextarea.vue';

export default {
  components: {
    QuillTextarea,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    canAddItineraryCollection: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    content: {
      required,
      maxLength: maxLength(10000),
      minLength: minLength(3),
    },
  },
  data() {
    return ({
      v$: useVuelidate(),
      loading: true,
      submitError: null,
      content: '',
      itinerariesCollections: [],
      selectedItinerariesCollections: [],
    });
  },
  computed: {
    contentErrors() {
      return getErrorMessages(this.v$.content, 'Content');
    },
  },
  mounted() {
    this.loadData();
    if (this.canAddItineraryCollection) {
      this.loadItineraryCollections();
    }
  },
  methods: {
    loadData() {
      axios.get(`${getBackendAdminHost(this.$store)}/admin/page/${this.slug.toUpperCase()}`, { withCredentials: true })
        .then((response) => {
          this.applyResponseData(response.data);
        }).catch(() => {
          this.submitError = 'Error during request';
        }).finally(() => {
          this.loading = false;
        });
    },
    loadItineraryCollections() {
      axios.get(`${getBackendAdminHost(this.$store)}/admin/itineraries-collection/public`, { withCredentials: true })
        .then((response) => {
          this.itinerariesCollections = response.data;
        }).catch(() => {
          this.submitError = 'Error during request';
        }).finally(() => {
          this.loading = false;
        });
    },
    applyResponseData(data) {
      this.content = data.content || '';
      this.selectedItinerariesCollections = data.linkedItinerariesCollectionsIds || [];
    },
    handleSave() {
      this.loading = true;
      this.submitError = null;

      const requestBody = {
        content: this.content,
        linkedItinerariesCollectionsIds: this.selectedItinerariesCollections,
      };

      axios.put(`${getBackendAdminHost(this.$store)}/admin/page/${this.slug.toUpperCase()}`, requestBody, { withCredentials: true })
        .then(() => {
          this.loadData();
          this.v$.$dirty = false;
          this.submitError = 'Saved!';
        }).catch(() => {
          this.submitError = 'Error during request';
        }).finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
