<template>
  <LoaderPlaceholder
    v-if="loading"
  />
  <div v-else>
    <vue-good-table
      :columns="columns"
      :rows="entries"
      :pagination-options="{
        enabled: true,
        perPage: 30,
        perPageDropdown: [15, 30, 50, 100],
        dropdownAllowAll: false,
      }"
      :search-options="{
        enabled: true,
      }"
      style-class="vgt-table"
    >
      <template #table-row="props">
        <span v-if="props.column.field === 'name'">
          <router-link
            :to="getEntryLink(props.row)"
            target="_blank"
          >
            <div class="text-subtitle-1 text-decoration-underline">
              {{ props.row.name }}
            </div>
          </router-link>
        </span>
        <span
          v-else-if="props.column.field === 'linked'"
          class="m-n4"
        >
          <v-checkbox-btn
            v-model="props.row.linked"
            density="compact"
            @click.stop="handleSwitchLink($event, props.row.uuid)"
          />
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>

import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import { mapGeoBaseTypeId } from '@/config/geoBaseTypes';

export default {
  name: 'GeoBaseLinkTable',
  props: {
    sourceUuid: String,
  },
  data() {
    return {
      entries: [],
      loading: true,
      columns: [
        {
          label: 'Linked',
          field: 'linked',
          type: 'boolean',
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Type',
          field: 'geoBaseType',
          filterOptions: {
            enabled: true,
          },
        },
      ],
    };
  },
  created() {
  },
  mounted() {
    this.loadLinks();
  },
  methods: {
    handleSwitchLink(event, entryUuid) {
      event.preventDefault();
      axios.post(`${getBackendAdminHost(this.$store)}/admin/link-geo-base/${this.sourceUuid}/${entryUuid}`, {}, { withCredentials: true })
        .then(() => {
          const changedEntry = this.entries.find((d) => d.uuid === entryUuid);
          changedEntry.linked = !changedEntry.linked;
          this.entries = [...this.entries]; // update
        })
        .catch(() => {
          this.submitError = 'Error switching active state';
        });
    },
    loadLinks() {
      axios.get(`${getBackendAdminHost(this.$store)}/admin/link-geo-base/get-meta/${this.sourceUuid}`, { withCredentials: true })
        .then((response) => {
          this.processGetResponse(response.data);

          this.loading = false;
        })
        .catch(() => {
          this.submitError = 'Error loading entries';
        });
    },
    processGetResponse(responseData) {
      const {
        linkedEntities,
        unlinkedEntities,
      } = responseData;
      this.entries = [...linkedEntities.map((l) => ({
        ...l,
        linked: true,
        geoBaseType: mapGeoBaseTypeId(l.geoBaseTypeId).name,
      })),
      ...unlinkedEntities.map((u) => ({
        ...u,
        linked: false,
        geoBaseType: mapGeoBaseTypeId(u.geoBaseTypeId).name,
      }))];
    },
    getEntryLink(row) {
      return `/${mapGeoBaseTypeId(row.geoBaseTypeId).path}/${row.uuid}`;
    },
  },
};
</script>
