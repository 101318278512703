<template>
  <v-skeleton-loader
    v-if="showLoader"
    type="card, list-item, list-item, list-item, list-item, list-item, list-item, list-item, list-item, list-item, list-item"
    boilerplate
    height="500"
    class="mx-auto"
  />
</template>

<script>
export default {
  name: 'LoaderPlaceholder',
  data() {
    return {
      showLoader: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showLoader = true;
    }, 250);
  },
};
</script>

<style scoped>
/* Optionales Styling */
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
</style>
