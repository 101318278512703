<template>
  <form>
    <page-top-area header-text="Login">
      <template #button-area>
        <v-btn
          prepend-icon="mdi-login-variant"
          variant="text"
          color="#4485ca"
          @click="handleRegister"
        >
          Register for free
        </v-btn>
      </template>
    </page-top-area>
    <v-row v-if="errorMessageType">
      <v-col>
        <v-card
          class="mt-7 mb-4"
          elevation="2"
        >
          <v-container
            v-if="errorMessageType==='SUBPAGE_NOT_PUBLIC'"
            style="background-color: #fff484"
          >
            The requested subpage is not public. To view the page you need to login in as an admin.
            <br>
            <div class="text-caption">
              To make it public for everyone please contact support: mail@yourtravelitinerary.com
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="username"
          :error-messages="usernameErrors"
          label="Username"
          required
          @input="v$.username.$touch()"
          @blur="v$.username.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="password"
          :error-messages="passwordErrors"
          label="Password"
          required
          type="password"
          @input="v$.password.$touch()"
          @blur="v$.password.$touch()"
        />
        <v-btn
          variant="text"
          color="#4485ca"
          class="pl-0"
          @click="handlePasswordForgotten"
        >
          Password forgotten?
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col
        cols="12"
      >
        <div class="text-caption float-left">
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>
        <v-btn
          class="float-right d-inline"
          :disabled="!v$.$dirty || v$.$error"
          :loading="loading"
          color="primary"
          @click="handleLogin"
        >
          Login
        </v-btn>
      </v-col>
    </v-row>
    <RequestErrorNotification :error="submitError" />
  </form>
</template>

<script>
import {
  required, maxLength, minLength,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { getErrorMessages } from '@/utils/validationUtils';
import axios from 'axios';
import { getCRMBackendHost } from '@/utils/urlUtils';
import { deleteCookie } from '@/utils/cookieUtils';

export default {
  components: {},
  validations: {
    username: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(3),
    },
    password: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(8),
    },
  },
  data() {
    return ({
      v$: useVuelidate(),
      loading: false,
      username: '',
      password: '',
      submitError: null,
      errorMessageType: this.$route.query.errorMessageType,
    });
  },
  computed: {
    usernameErrors() {
      return getErrorMessages(this.v$.username, 'Username');
    },
    passwordErrors() {
      return getErrorMessages(this.v$.password, 'Password');
    },
  },
  mounted() {
    this.handleLogout();
  },
  methods: {
    handleLogout() {
      this.$store.commit('logout');
    },
    handleRegister() {
      this.$router.push('/register');
    },
    handlePasswordForgotten() {
      this.$router.push('/passwordForgotten');
    },
    handleLogin() {
      this.loading = true;
      this.submitError = null;

      const requestBody = {
        username: this.username,
        password: this.password,
      };

      axios.post(`${getCRMBackendHost()}/login`, requestBody, { withCredentials: true })
        .then((response) => {
          this.$store.commit('login', response.data.domains);
          this.redirectAfterLogin();
        })
        .catch((e) => {
          console.error(e);
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    redirectAfterLogin() {
      const redirectQuery = this.$router.currentRoute.value.query.redirect;
      if (redirectQuery && redirectQuery.includes('/login') === false) {
        this.$router.push(redirectQuery);
      } else {
        this.$router.push('/admin-overview');
      }
    },
  },
};
</script>
