// Keep in sync with admin frontend and backend
export const tagTypes = {
  RATING: {
    id: 0,
    name: 'Rating',
  },
  GEOGRAPHY: {
    id: 1,
    name: 'Geography',
  },
  DESTINATION_SIZE: {
    id: 2,
    name: 'Destination size',
  },
  ACTIVITY: {
    id: 3,
    name: 'Activity',
  },
  POI: {
    id: 4,
    name: 'Point of interest',
  },
  TOURISTY: {
    id: 5,
    name: 'Touristy',
  },
};
export const tagTypesAsList = () => Object.values(tagTypes);

export const mapTagTypeId = (id) => {
  const result = Object.values(tagTypes)
    .find((geoBaseType) => geoBaseType.id === id);
  if (result) {
    return result;
  }
  console.error('invalid tag type id: ', id);
  return null;
};
