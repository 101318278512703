<template>
  <div>
    <page-top-area
      header-text="Itineraries Collections"
      info-text="Manage all itineraries collections"
    >
      <template #button-area>
        <v-btn
          prepend-icon="mdi-plus-circle"
          variant="text"
          color="#4485ca"
          @click="handleCreate"
        >
          Create new itineraries collection
        </v-btn>
      </template>
    </page-top-area>
    <LoaderPlaceholder
      v-if="loading"
    />
    <div v-else-if="itinerariesCollections.length==0">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              No itineraries collections found.
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <vue-good-table
        :columns="columns"
        :rows="itinerariesCollections"
        :pagination-options="{
          enabled: true,
          perPage: 30,
          perPageDropdown: [15, 30, 50, 100],
          dropdownAllowAll: false,
        }"
        :search-options="{
          enabled: true,
        }"
        style-class="vgt-table"
      >
        <template #table-row="props">
          <span v-if="props.column.field === 'name'">
            <router-link :to="'/itineraries-collection/' + props.row.uuid">
              <div class="text-subtitle-1">
                {{ props.row.name }}
              </div>
            </router-link>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>

import GeoEntityListView from '@/views/GeoEntityListView.vue';
import PageTopArea from '@/components/PageTopArea.vue';
import { geoBaseTypes } from '@/config/geoBaseTypes';
import { getBackendAdminHost, getCurrentSubdomain } from '@/utils/urlUtils';
import axios from 'axios';
import AiWizardCompleteDialog from '@/components/AiWizardCompleteDialog.vue';

export default {
  components: { PageTopArea },
  data() {
    return {
      loading: false,
      itinerariesCollections: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
          },
        },
      ],
    };
  },
  mounted() {
    this.loadItinerariesCollections();
  },
  methods: {
    handleCreate() {
      this.$router.push('itineraries-collection/new');
    },
    loadItinerariesCollections() {
      this.loading = true;
      axios.get(`${getBackendAdminHost(this.$store)}/admin/itineraries-collection`, { withCredentials: true })
        .then((response) => {
          this.itinerariesCollections = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
