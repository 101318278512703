import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import visibleConditionTypes from '@/types/visibleConditionTypes';
import InfoCardTemplateView from '@/views/InfoCardTemplateView.vue';
import InfoCardTemplateListView from '@/views/InfoCardTemplateListView.vue';
import RouteView from '@/views/RouteView.vue';
import RouteListView from '@/views/RouteListView.vue';
import AgencyView from '@/views/AgencyView.vue';
import AgencyListView from '@/views/AgencyListView.vue';
import InfoCardView from '@/views/InfoCardView.vue';
import GeoEntityView from '@/views/GeoEntityView.vue';
import StartView from '../views/StartView.vue';
import AdminOverviewView from '../views/AdminOverviewView.vue';
import DomainView from '../views/DomainView.vue';
import AccommodationsListView from '../views/AccommodationListView.vue';
import ItineraryListView from '../views/ItineraryListView.vue';
import ItineraryCollectionListView from '../views/ItineraryCollectionListView.vue';
import ItineraryCollectionView from '../views/ItineraryCollectionView.vue';
import ItineraryView from '../views/ItineraryView.vue';
import AreasListView from '../views/AreasListView.vue';
import InfrastructuresListView from '../views/InfrastructuresListView.vue';
import RegionsListView from '../views/RegionsListView.vue';
import RegionView from '../views/RegionView.vue';
import TagsListView from '../views/TagsListView.vue';
import TagView from '../views/TagView.vue';
import ExperiencesListView from '../views/ExperiencesListView.vue';
import AttractionsListView from '../views/AttractionsListView.vue';
import WaysListView from '../views/WaysListView.vue';
import DestinationsView from '../views/DestinationsListView.vue';
import AttractionView from '../views/AttractionView.vue';
import WayView from '../views/WayView.vue';
import AreaView from '../views/AreaView.vue';
import ExperienceView from '../views/ExperienceView.vue';
import InfrastructureView from '../views/InfrastructureView.vue';
import AccommodationView from '../views/AccommodationView.vue';
import DestinationView from '../views/DestinationView.vue';
import RegistrationView from '../views/RegistrationView.vue';
import RegistrationConfirmView from '../views/RegistrationConfirmView.vue';
import PasswordForgottenView from '../views/PasswordForgottenView.vue';
import PasswordChangeAfterResetView from '../views/PasswordChangeAfterResetView.vue';
import PasswordChangeLoggedInView from '../views/PasswordChangeLoggedInView.vue';
import PageEditHomepageView from '../views/PageEditHomepageView.vue';
import PageEditExploreView from '../views/PageEditExploreView.vue';
import PageEditAboutView from '../views/PageEditAboutView.vue';
import ArticlesView from '../views/ArticlesView.vue';
import ArticleView from '../views/ArticleView.vue';
import WizardNewEntities from '../views/ImportNewGeoEntities.vue';
import SettingsView from '../views/SettingsView.vue';
import EnabledCountriesView from '../views/EnabledCountriesView.vue';

export const routeGroups = {
  beforeAll: {
    name: 'beforeAll',
    noTitle: true,
  },
  configuration: {
    name: 'Configuration',
  },
  places: {
    name: 'Places',
  },
  itineraries: {
    name: 'Itineraries',
  },
  textContent: {
    name: 'Content',
  },
  account: {
    name: 'Account',
  },
};

export const routes = [
  {
    path: '/',
    name: 'YTI',
    component: StartView,
    visibleInMenu: visibleConditionTypes.NEVER,
    group: routeGroups.configuration,
    icon: 'mdi-domain',
  },
  {
    path: '/223e',
    name: 'YTIdd',
    component: GeoEntityView,
    visibleInMenu: visibleConditionTypes.NEVER,
    group: routeGroups.configuration,
    icon: 'mdi-domain',
  },
  {
    path: '/admin-overview',
    name: 'Overview',
    component: AdminOverviewView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.beforeAll,
    icon: 'mdi-domain',
  },
  {
    path: '/settings',
    name: 'General',
    component: SettingsView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.configuration,
    icon: 'mdi-shopping',
  },
  {
    path: '/domains',
    name: 'Domain',
    component: DomainView,
    visibleInMenu: visibleConditionTypes.NEVER,
    group: routeGroups.configuration,
    icon: 'mdi-domain',
  },
  {
    path: '/enabled-countries',
    name: 'Enabled Countries',
    component: EnabledCountriesView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.configuration,
    icon: 'mdi-earth',
  },
  {
    path: '/regions',
    name: 'Regions',
    component: RegionsListView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.configuration,
    icon: 'mdi-book-open-page-variant',
  },
  {
    path: '/region/:uuid',
    name: 'Region',
    component: RegionView,
    group: routeGroups.configuration,
    icon: 'mdi-book-open-page-variant',
  },
  {
    path: '/tags',
    name: 'Tags',
    component: TagsListView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.configuration,
    icon: 'mdi-label',
  },
  {
    path: '/tag/:uuid',
    name: 'Tag',
    component: TagView,
    group: routeGroups.configuration,
    icon: 'mdi-label',
  },
  {
    path: '/info-card-template',
    name: 'Info Card Templates',
    component: InfoCardTemplateListView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.configuration,
    icon: 'mdi-information-outline',
  },
  {
    path: '/info-card-template/:uuid',
    name: 'Info Card Template',
    component: InfoCardTemplateView,
    group: routeGroups.configuration,
    icon: 'mdi-information-outline',
  },
  {
    path: '/route',
    name: 'Routes',
    component: RouteListView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.configuration,
    icon: 'mdi-map-marker-distance',
  },
  {
    path: '/route/:uuid',
    name: 'Route',
    component: RouteView,
    group: routeGroups.configuration,
    icon: 'mdi-map-marker-distance',
  },
  {
    path: '/agencies',
    name: 'Agencies',
    component: AgencyListView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.configuration,
    icon: 'mdi-ticket-account',
  },
  {
    path: '/agency/:uuid',
    name: 'Agency',
    component: AgencyView,
    group: routeGroups.configuration,
    icon: 'mdi-map-marker-distance',
  },
  {
    path: '/info-card/:geoBaseTypePath/:geoBaseUuid/:uuid',
    // 24ea55ab-88b0-4bee-b0f6-8bf9bfaf262b/info-card//6eba2d9e-431d-4393-b04d-1365568ac717
    name: 'Info Card',
    component: InfoCardView,
    group: routeGroups.configuration,
    icon: 'mdi-information-outline',
  },
  {
    path: '/itineraries',
    name: 'Itineraries',
    component: ItineraryListView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.itineraries,
    icon: 'mdi-map-marker-distance',
  },
  {
    path: '/itinerary/:id',
    name: 'Itinerary',
    component: ItineraryView,
    group: routeGroups.itineraries,
    icon: 'mdi-hotel',
  },
  {
    path: '/itineraries-collections',
    name: 'Itineraries collections',
    component: ItineraryCollectionListView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.itineraries,
    icon: 'mdi-group',
  },
  {
    path: '/itineraries-collection/:id',
    name: 'Itinerary collection',
    component: ItineraryCollectionView,
    group: routeGroups.itineraries,
    icon: 'mdi-hotel',
  },
  {
    path: '/attractions',
    name: 'Attractions',
    component: AttractionsListView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.places,
    icon: 'mdi-binoculars',
  },
  {
    path: '/attraction/:uuid',
    name: 'Attraction',
    component: AttractionView,
    group: routeGroups.places,
    icon: 'mdi-binoculars',
  },
  {
    path: '/ways',
    name: 'Ways',
    component: WaysListView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.places,
    icon: 'mdi-navigation',
  },
  {
    path: '/way/:uuid',
    name: 'Way',
    component: WayView,
    group: routeGroups.places,
    icon: 'mdi-navigation',
  },
  {
    path: '/areas',
    name: 'Areas',
    component: AreasListView,
    visibleInMenu: visibleConditionTypes.NEVER,
    // visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.places,
    icon: 'mdi-pine-tree',
  },
  {
    path: '/area/:uuid',
    name: 'Area',
    component: AreaView,
    group: routeGroups.places,
    icon: 'mdi-pine-tree',
  },
  {
    path: '/accommodations',
    name: 'Accommodations',
    component: AccommodationsListView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.places,
    icon: 'mdi-bed',
  },
  {
    path: '/accommodation/:uuid',
    name: 'Accommodation',
    component: AccommodationView,
    group: routeGroups.places,
    icon: 'mdi-hotel',
  },
  {
    path: '/experiences',
    name: 'Experiences',
    component: ExperiencesListView,
    visibleInMenu: visibleConditionTypes.NEVER,
    // visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.places,
    icon: 'mdi-ticket',
  },
  {
    path: '/experience/:uuid',
    name: 'Experience',
    component: ExperienceView,
    group: routeGroups.places,
    icon: 'mdi-ticket',
  },
  {
    path: '/infrastructures',
    name: 'Infrastructures',
    component: InfrastructuresListView,
    visibleInMenu: visibleConditionTypes.NEVER,
    // visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.places,
    icon: 'mdi-airport',
  },
  {
    path: '/infrastructure/:uuid',
    name: 'Infrastructure',
    component: InfrastructureView,
    group: routeGroups.places,
    icon: 'mdi-airport',
  },
  {
    path: '/destination/:uuid',
    name: 'Destination',
    component: DestinationView,
    group: routeGroups.places,
    icon: 'mdi-map-marker',
  },
  {
    path: '/destinations',
    name: 'Destinations',
    component: DestinationsView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.places,
    icon: 'mdi-city',
  }, {
    path: '/login',
    name: 'Login',
    component: LoginView,
    visibleInMenu: visibleConditionTypes.NEVER,
    group: routeGroups.account,
    icon: 'mdi-login',
  }, {
    path: '/register',
    name: 'Register',
    component: RegistrationView,
    visibleInMenu: visibleConditionTypes.NEVER,
    group: routeGroups.account,
    icon: 'mdi-login-variant',
  }, {
    path: '/registrationConfirm',
    name: 'Registration Confirm',
    component: RegistrationConfirmView,
    visibleInMenu: visibleConditionTypes.NEVER,
    group: routeGroups.account,
    icon: 'mdi-login-variant',
  }, {
    path: '/passwordForgotten',
    name: 'Password Forgotten',
    component: PasswordForgottenView,
    visibleInMenu: visibleConditionTypes.NEVER,
    group: routeGroups.account,
    icon: 'mdi-lock-outline',
  }, {
    path: '/user/changePassword',
    name: 'Change Password',
    component: PasswordChangeAfterResetView,
    visibleInMenu: visibleConditionTypes.NEVER,
    group: routeGroups.account,
    icon: 'mdi-lock-reset',
  }, {
    path: '/user/updatePassword',
    name: 'Update Password',
    component: PasswordChangeLoggedInView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.account,
    icon: 'mdi-lock-reset',
  }, {
    path: '/login?log=out',
    name: 'Logout',
    component: LoginView,
    visibleInMenu: visibleConditionTypes.NEVER,
    group: routeGroups.account,
    icon: 'mdi-logout-variant',
  }, {
    path: '/page/homepage',
    name: 'Page - Homepage',
    component: PageEditHomepageView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.textContent,
    icon: 'mdi-comment-text',
  }, {
    path: '/page/explore',
    name: 'Page - Explore',
    component: PageEditExploreView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.textContent,
    icon: 'mdi-navigation',
  }, {
    path: '/page/about',
    name: 'Page - About',
    component: PageEditAboutView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.textContent,
    icon: 'mdi-comment-text-outline',
  }, {
    path: '/articles',
    name: 'Articles',
    component: ArticlesView,
    visibleInMenu: visibleConditionTypes.ONLY_LOGGED_IN,
    group: routeGroups.textContent,
    icon: 'mdi-comment-multiple',
  }, {
    path: '/article/:articleId',
    name: 'Article',
    component: ArticleView,
    group: routeGroups.textContent,
    icon: 'mdi-comment',
  }, {
    path: '/import/new-entries/:slug',
    name: 'Import - new Entries',
    component: WizardNewEntities,
    group: routeGroups.textContent,
    icon: 'mdi-wizard',
  },
];

export const getVisibleRouteGroups = (loggedIn) => {
  const visibleRoutes = routes.filter((route) => route.visibleInMenu === visibleConditionTypes.ALWAYS
    || (route.visibleInMenu === visibleConditionTypes.ONLY_LOGGED_IN && loggedIn)
    || (route.visibleInMenu === visibleConditionTypes.ONLY_LOGGED_OUT && !loggedIn));

  const routeGroupsAsArray = Object.keys(routeGroups).map((key) => routeGroups[key]);
  return routeGroupsAsArray.map((routeGroup) => ({
    ...routeGroup,
    routes: visibleRoutes.filter((route) => route.group === routeGroup),
  })).filter((routeGroup) => routeGroup.routes.length > 0);
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
