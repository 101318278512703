<template>
  <page-top-area
    header-text="Edit homepage text"
    info-text="Set homepage text"
  >
    <template #button-area />
  </page-top-area>
  <EditTextContent
    slug="HOMEPAGE"
    :can-add-itinerary-collection="true"
  />
</template>

<script>
import EditTextContent from '@/components/EditTextContent.vue';

export default {
  components: { EditTextContent },
};
</script>
