<template>
  <v-autocomplete
    v-model="autocompleteModel"
    item-title="name"
    :disabled="loading"
    :items="regionList"
    :error-messages="regionErrors"
    label="Regions"
    :auto-select-first="false"
    :return-object="true"
    :multiple="true"
    :chips="true"
    @blur="v$.regions.$touch()"
  />
</template>

<script>

import axios from 'axios';
import { startCase } from 'lodash';
import { getBackendAdminHost } from '@/utils/urlUtils';

export default {
  name: 'RegionSelection',
  props: {
    selectedValues: Array,
    regionErrors: Array,
    v$: Object,
  },
  data() {
    return {
      loading: true,
      regionList: [],
    };
  },
  computed: {
    autocompleteModel: {
      get() {
        return !this.loading && this.selectedValues.map((selectedValue) => this.regionList.find((region) => region.uuid === selectedValue)).filter((selectedValue) => selectedValue);
      },
      set(value) {
        this.v$.regions.$touch();
        this.$emit('update:selectedValues', value.map((v) => this.regionList.find((region) => (v.name && v.name.toLowerCase() === region.name.toLowerCase())
          || (typeof v === 'string' && region.name.toLowerCase() === v.toLowerCase())).uuid));
      },
    },
  },
  mounted() {
    axios.get(`${getBackendAdminHost(this.$store)}/admin/region`, { withCredentials: true })
      .then((response) => {
        this.regionList = response.data.map((region) => ({ ...region, name: startCase(region.name) }));
        this.loading = false;
      });
  },
};
</script>
