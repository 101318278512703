<template>
  <QuillEditor
    v-model:content="contentModel"
    theme="snow"
    :style="{height: '400px', marginBottom: '30px', width: '100%'}"
    content-type="html"
    :modules="modules"
    toolbar="full"
    :placeholder="placeholder"
  />
</template>

<script>

import BlotFormatter from 'quill-blot-formatter';
import MagicUrl from 'quill-magic-url';
import TableUI from 'quill-table-ui';
import ImageUploader from 'quill-image-uploader';
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';

export default {
  name: 'QuillTextarea',
  props: {
    content: String,
    placeholder: String,
  },
  data() {
    return {
      modules: [
        {
          name: 'magicUrl',
          module: MagicUrl,
        },
        {
          name: 'tableUI',
          module: TableUI,
        },
        {
          name: 'blotFormatter',
          module: BlotFormatter,
        },
        {
          name: 'imageUploader',
          module: ImageUploader,
          options: {
            upload: (file) => new Promise((resolve, reject) => {
              const formData = new FormData();
              formData.append('file', file);

              axios.post(`${getBackendAdminHost(this.$store)}/admin/image/upload`, formData, {
                withCredentials: true,
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
                .then((res) => {
                  console.log(res);
                  resolve(res.data.path);
                })
                .catch((err) => {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  reject('Upload failed');
                  console.error('Error:', err);
                });
            }),
          },
        }],
    };
  },
  computed: {
    contentModel: {
      get() {
        return this.content;
      },
      set(value) {
        this.$emit('update:content', value);
      },
    },
  },
  created() {

  },
};
</script>
