<template>
  <v-dialog
    v-model="modalOpen"
  >
    <v-card>
      <v-card-text>
        Generate content with ai
      </v-card-text>
      <v-card-item>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="entityName"
              label="For what?"
              required
            />
          </v-col>
          <v-col
            cols="4"
            class="d-flex align-center"
          >
            <v-select
              v-model="selectedRequestType"
              label="Request type?"
              :items="requestTypesAsList"
            />
          </v-col>
          <v-col
            cols="4"
            class="d-flex align-center"
          >
            <v-btn
              color="primary"
              :disabled="loading"
              @click="onGenerate"
            >
              Generate
            </v-btn>
          </v-col>
        </v-row>
      </v-card-item>
      <v-card-actions>
        <v-row justify="space-between">
          <v-col
            cols="4"
          />
          <v-col
            cols="4"
          />
          <v-col
            cols="4"
          >
            <v-btn
              class="float-right"
              @click="onAbort"
            >
              Close
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <RequestErrorNotification :error="submitError" />
</template>

<script>

import RequestErrorNotification from '@/components/RequestErrorNotification.vue';
import { requestTypesAsList } from '@/config/completeWizardRequestTypes';
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';

export default {
  name: 'AiWizardCompleteDialog',
  components: { RequestErrorNotification },
  props: {
    open: Boolean,
    onAbort: Function,
    onConfirm: Function,
    initialEntityName: String,
    initialRequestType: String,
    geoBaseType: Object,
    countryId: Number,
  },
  data() {
    return {
      entityName: '',
      submitError: null,
      loading: false,
      selectedRequestType: this.initialRequestType,
      requestTypesAsList: requestTypesAsList(),
    };
  },
  computed: {
    modalOpen: {
      get() {
        return this.open;
      },
      set() {
      },
    },
  },
  watch: {
    initialEntityName() {
      this.entityName = this.initialEntityName;
    },
    initialRequestType() {
      this.selectedRequestType = this.initialRequestType;
    },
  },
  created() {
  },
  methods: {
    onGenerate() {
      this.loading = true;
      axios.post(`${getBackendAdminHost(this.$store)}/admin/ai-wizard/${this.geoBaseType.id}/generate-complete`, this.buildRequest(), { withCredentials: true })
        .then((response) => {
          location.reload();
        })
        .catch((error) => {
          console.log(error);
          this.submitError = 'Error while generating content';
        }).finally(() => {
          this.loading = false;
        });
    },
    buildRequest() {
      return {
        entityName: this.entityName,
        countryId: this.countryId,
        requestType: this.selectedRequestType,
      };
    },
  },
};
</script>
