<template>
  <div>
    <page-top-area
      header-text="Info Card Template"
      info-text="Manage all info card templates"
    >
      <template #button-area>
        <v-btn
          prepend-icon="mdi-plus-circle"
          variant="text"
          color="#4485ca"
          @click="handleCreate"
        >
          New info card template
        </v-btn>
      </template>
    </page-top-area>
    <LoaderPlaceholder
      v-if="loading"
    />
    <div v-else-if="entries.length===0">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              No info card templates found.
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <vue-good-table
        :columns="columns"
        :rows="entries"
        :pagination-options="{
          enabled: true,
          perPage: 30,
          perPageDropdown: [15, 30, 50, 100],
          dropdownAllowAll: false,
        }"
        style-class="vgt-table"
      >
        <template #table-row="props">
          <span v-if="props.column.field === 'question'">
            <router-link :to="'/info-card-template/'+props.row.uuid">
              <div class="text-subtitle-1 text-decoration-underline">
                {{ props.row.question }}
              </div>
            </router-link>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
    <RequestErrorNotification :error="submitError" />
  </div>
</template>

<script>
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';

export default {
  components: {},
  data() {
    return {
      loading: true,
      submitError: null,
      entries: [],
      columns: [
        {
          label: 'Question',
          field: 'question',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: '# Occurrences',
          field: 'occurrences',
          filterOptions: {
            enabled: true,
          },
        },
      ],
    };
  },
  mounted() {
    this.axios.get(`${getBackendAdminHost(this.$store)}/admin/info-card-template`, { withCredentials: true })
      .then((response) => {
        this.entries = response.data.map((entry) => ({
          ...entry,
        }));
        this.loading = false;
      })
      .catch(() => {
        this.submitError = 'Error during request';
      });
  },
  methods: {
    handleCreate() {
      this.$router.push('/info-card-template/new');
    },
  },
};
</script>
