<template>
  <GeoEntityView
    :geo-base-type="geoBaseType"
    info-text-general="All experiences"
  >
    <template
      v-if="$route.params.uuid!=='new'"
      #linksTab
    >
      <page-top-area
        header-text="Links"
        :info-text="'Link your experience'"
      />
      <GeoBaseLinkTable
        source-slug="experience"
        :source-uuid="$route.params.uuid"
      />
    </template>
  </GeoEntityView>
</template>

<script>

import GeoEntityView from '@/views/GeoEntityView.vue';
import GeoBaseLinkTable from '@/components/GeoBaseLinkTable.vue';
import { geoBaseTypes } from '@/config/geoBaseTypes';

export default {
  components: {
    GeoEntityView,
    GeoBaseLinkTable,
  },
  data() {
    return ({
      geoBaseType: geoBaseTypes.EXPERIENCE,
    });
  },

};
</script>
