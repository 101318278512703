<template>
  <LoaderPlaceholder
    v-if="loading"
  />
  <div v-else>
    <page-top-area
      header-text="Article"
      info-text="Manage all articles"
    >
      <template #button-area>
        <v-btn
          prepend-icon="mdi-plus-circle"
          variant="text"
          color="#4485ca"
          @click="handleCreate"
        >
          New article
        </v-btn>
      </template>
    </page-top-area>
    <vue-good-table
      :columns="columns"
      :rows="articles"
      :pagination-options="{
        enabled: true,
        perPage: 30,
        perPageDropdown: [15, 30, 50, 100],
        dropdownAllowAll: false,
      }"
      style-class="vgt-table striped"
    >
      <template #table-row="props">
        <span v-if="props.column.field === 'after'">
          <router-link :to="'/article/'+props.row.id">
            {{ 'Edit' }}
          </router-link>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <RequestErrorNotification :error="submitError" />
  </div>
</template>

<script>
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';

export default {
  components: { },
  data() {
    return {
      loading: true,
      submitError: null,
      articles: [
      ],
      columns: [{
        label: 'Title',
        field: 'title',
        filterOptions: {
          enabled: true,
        },
      },
      {
        label: 'Updated',
        field: 'updated',
        type: 'datetime',
      },
      {
        label: 'Order',
        field: 'order',
        filterOptions: {
          enabled: false,
        },
      },
      {
        label: '',
        field: 'after',
        sortable: false,
      },
      ],
    };
  },
  mounted() {
    axios.get(`${getBackendAdminHost(this.$store)}/admin/article`, { withCredentials: true })
      .then((response) => {
        this.articles = response.data;
        this.loading = false;
      }).catch(() => {
        this.submitError = 'Error during request';
      });
  },
  methods: {
    handleCreate() {
      this.$router.push('/article/new');
    },
  },
};
</script>
