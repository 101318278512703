<template>
  <page-top-area
    header-text="Country Selection"
    info-text="Select country selections"
  >
    <template #button-area />
  </page-top-area>
  <v-combobox
    v-model="selectedCountries"
    :items="countries"
    item-text="name"
    item-value="id"
    item-title="name"
    label="Enabled countries"
    multiple
    chips
    @update:model-value="handleTouchCountries"
  />
  <form>
    <v-row justify="space-between">
      <v-col cols="12">
        <v-container class="d-flex justify-center">
          <v-btn
            :disabled="v$.$error || !touchedOneCountry"
            :loading="loading"
            color="primary"
            @click="handleSave"
          >
            Save changes
          </v-btn>
        </v-container>
      </v-col>
    </v-row>
    <RequestErrorNotification :error="submitError" />
  </form>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import PageTopArea from '@/components/PageTopArea.vue';

export default {
  components: { PageTopArea },
  validations: {},
  data() {
    return {
      v$: useVuelidate(),
      loading: false,
      submitError: null,
      countries: [],
      selectedCountries: [],
      touchedCountries: false,
    };
  },
  computed: {
    touchedOneCountry() {
      return this.touchedCountries;
    },
  },
  mounted() {
    this.loadCountries();
  },
  methods: {
    handleTouchCountries() {
      this.touchedCountries = true;
    },
    handleSave() {
      console.log(this.selectedCountries);
      axios
        .post(
          `${getBackendAdminHost(this.$store)}/admin/enabled-countries`,
          this.buildRequestObject(),
          { withCredentials: true },
        )
        .then(() => {
          this.submitError = 'Changes saved!';
          this.touchedCountries = false;
          this.$router.push('/enabled-countries');
        });
    },
    buildRequestObject() {
      return {
        countries: this.countries.map((country) => ({
          countryId: country.id,
          enabled: this.selectedCountries.map((c) => c.id).includes(country.id),
        })),
      };
    },
    loadCountries() {
      this.loading = true;
      axios
        .get(`${getBackendAdminHost(this.$store)}/admin/enabled-countries/list`, {
          withCredentials: true,
        })
        .then((response) => {
          this.countries = response.data.sort((a, b) => a.name.localeCompare(b.name));
          this.selectedCountries = this.countries
            .filter((country) => country.enabled)
            .map((country) => country.id);
          this.loading = false;
        })
        .catch(() => {
          this.submitError = 'Error during request';
        });
    },
  },
};
</script>
