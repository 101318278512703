<template>
  <form v-if="!loading">
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-card elevation="1">
          <v-card-title>
            Primary color
          </v-card-title>

          <v-card-text>
            <v-row
              justify="space-around"
              align="center"
              class="py-3"
            >
              <v-color-picker
                v-model="mainColor"
                flat
                :modes="['hexa']"
                hide-mode-switch
                elevation="0"
              />
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-card elevation="1">
          <v-card-title>
            Header
          </v-card-title>

          <v-card-text>
            <v-row
              class="py-3"
              justify="space-around"
              align="center"
            >
              <div class="text-subtitle-2">
                Background color:
              </div>
              <v-color-picker
                v-model="headerColor"
                flat
                :modes="['hexa']"
                hide-mode-switch
                elevation="0"
              />
            </v-row>
            <v-row
              class="py-3"
              justify="space-around"
              align="center"
            >
              <div class="text-subtitle-2">
                Text color:
              </div>
              <v-color-picker
                v-model="headerTextColor"
                flat
                :modes="['hexa']"
                hide-mode-switch
                elevation="0"
              />
            </v-row>
            <v-row
              class="py-3"
              justify="space-around"
              align="center"
            >
              <v-text-field
                v-model="headerShadow"
                placeholder="3"
                :error-messages="headerShadowErrors"
                label="Shadow"
                required
                @input="v$.headerShadow.$touch()"
                @blur="v$.headerShadow.$touch()"
              />
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-card elevation="1">
          <v-card-title>
            Website title
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="title"
              placeholder="Title of your itinerary planner"
              :error-messages="titleErrors"
              label="Title"
              required
              @input="v$.title.$touch()"
              @blur="v$.title.$touch()"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-card elevation="1">
          <v-card-title>
            Homepage teaser
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="teaserTitle"
              placeholder="Best itinerary planner ever"
              :error-messages="teaserTitleErrors"
              label="Teaser Title"
              required
              @input="v$.teaserTitle.$touch()"
              @blur="v$.teaserTitle.$touch()"
            />
            <v-text-field
              v-model="teaserSubtitle"
              placeholder="Yep, it's really great because you can do everything with it"
              :error-messages="teaserSubtitleErrors"
              label="Teaser Subtitle"
              required
              @input="v$.teaserSubtitle.$touch()"
              @blur="v$.teaserSubtitle.$touch()"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          class="float-right"
          :disabled="v$.$error"
          :loading="loading"
          color="primary"
          @click="handleSave"
        >
          Save Settings
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="1">
          <v-card-title>
            Logo
          </v-card-title>
          <v-card-text>
            <ImageUpload
              :images="logoImage ? [logoImage] : []"
              :upload-path="logoImageUploadPath"
              :reload-function="uploadedLogoImage"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="1">
          <v-card-title>
            Teaser Image
          </v-card-title>
          <v-card-text>
            <ImageUpload
              :images="teaserImage ? [teaserImage] : []"
              :upload-path="teaserImageUploadPath"
              :reload-function="uploadedTeaserImage"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <RequestErrorNotification :error="submitError" />
  </form>
</template>

<script>
import {
  required, maxLength, minLength,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import { getErrorMessages } from '@/utils/validationUtils';
import ImageUpload from '@/components/ImageUpload.vue';
import { settingTypes } from '@/config/settingTypes';

export default {
  components: {
    ImageUpload,
  },
  validations: {
    title: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(3),
    },
    teaserTitle: {
      required,
      maxLength: maxLength(50),
    },
    teaserSubtitle: {
      required,
      maxLength: maxLength(250),
    },
    headerShadow: {
      required,
      maxLength: maxLength(2),
    },
  },
  data() {
    return ({
      v$: useVuelidate(),
      loading: true,
      submitError: null,
      mainColor: '',
      headerColor: '',
      headerTextColor: '',
      headerShadow: '',
      title: '',
      teaserTitle: '',
      teaserSubtitle: '',
      logoImage: null,
      logoImageUploadPath: `${getBackendAdminHost(this.$store)}/admin/image/upload/LOGO`,
      teaserImage: null,
      teaserImageUploadPath: `${getBackendAdminHost(this.$store)}/admin/image/upload/TEASER_IMAGE`,
    });
  },
  computed: {
    titleErrors() {
      return getErrorMessages(this.v$.title, 'Title');
    },
    teaserTitleErrors() {
      return getErrorMessages(this.v$.teaserTitle, 'Teaser title');
    },
    teaserSubtitleErrors() {
      return getErrorMessages(this.v$.teaserSubtitle, 'Teaser subtitle');
    },
    headerShadowErrors() {
      return getErrorMessages(this.v$.headerShadow, 'Header shadow');
    },
  },
  mounted() {
    this.loadSettings();
  },
  methods: {
    uploadedLogoImage(response) {
      this.logoImage = response.data;
    },
    uploadedTeaserImage(response) {
      this.teaserImage = response.data;
    },
    loadSettings() {
      axios.get(`${getBackendAdminHost(this.$store)}/admin/setting`, { withCredentials: true })
        .then((response) => {
          this.applyResponseData(response.data.settings || []);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    applyResponseData(dataSettings) {
      this.mainColor = dataSettings.find((d) => d.key === settingTypes.MAIN_COLOR)?.value || '#4485caaa';
      this.headerColor = dataSettings.find((d) => d.key === settingTypes.HEADER_COLOR)?.value || '#4485caaa';
      this.headerTextColor = dataSettings.find((d) => d.key === settingTypes.HEADER_TEXT_COLOR)?.value || '#fefefe';
      this.headerShadow = dataSettings.find((d) => d.key === settingTypes.HEADER_SHADOW)?.value || '3';
      this.title = dataSettings.find((d) => d.key === settingTypes.TITLE)?.value || '';
      this.teaserTitle = dataSettings.find((d) => d.key === settingTypes.TEASER_TITLE)?.value || '';
      this.teaserSubtitle = dataSettings.find((d) => d.key === settingTypes.TEASER_SUBTITLE)?.value || '';
      this.logoImage = dataSettings.find((d) => d.key === settingTypes.LOGO)?.images[0] || '';
      this.teaserImage = dataSettings.find((d) => d.key === settingTypes.TEASER_IMAGE)?.images[0] || '';
    },
    buildRequestObjectEntry(key, value) {
      return {
        key,
        value,
      };
    },
    handleSave() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }

      this.loading = true;
      this.submitError = null;

      const requestBody = {
        settings: [
          this.buildRequestObjectEntry(settingTypes.MAIN_COLOR, this.mainColor),
          this.buildRequestObjectEntry(settingTypes.HEADER_COLOR, this.headerColor),
          this.buildRequestObjectEntry(settingTypes.HEADER_SHADOW, this.headerShadow),
          this.buildRequestObjectEntry(settingTypes.HEADER_TEXT_COLOR, this.headerTextColor),
          this.buildRequestObjectEntry(settingTypes.TITLE, this.title),
          this.buildRequestObjectEntry(settingTypes.TEASER_TITLE, this.teaserTitle),
          this.buildRequestObjectEntry(settingTypes.TEASER_SUBTITLE, this.teaserSubtitle),
        ],
      };

      axios.post(`${getBackendAdminHost(this.$store)}/admin/setting`, requestBody, { withCredentials: true })
        .then(() => {
          this.loading = false;
          this.submitError = 'Settings saved!';
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
  },
};
</script>
