<template>
  <v-card>
    <v-card-title>
      <span v-if="editMode">
        <v-text-field
          v-model="name"
          label="Name"
          outlined
          :error-messages="nameErrors"
          required
        />
        <QuillTextarea
          v-if="!loading"
          v-model:content="description"
          placeholder="Description"
        />
      </span>
      <span v-else>
        {{
          name
        }}
        <span
          v-if="routeData.startRoutePlace || routeData.startGeoBase?.uuid"
          class="text-subtitle-2"
        >
          {{ ' (' + (routeData.startRoutePlace || routeData.startGeoBase)?.name + ' - ' + (routeData.endRoutePlace || routeData.endGeoBase)?.name + ')' }}
        </span>
        <v-btn
          icon
          class="float-end"
          size="small"
          @click="handleEdit"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-row v-if="description">
          <v-col
            cols="12"
            class="my-2"
          >
            <span
              class="text-body-1"
              v-html="description"
            />
          </v-col>
        </v-row>
      </span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <span v-if="editMode && !onlyNameAndDescriptionEditable">
            <v-text-field
              v-model="costs"
              label="Costs"
              outlined
              :error-messages="costsErrors"
              required
            />
          </span>
          <span v-else>
            <span v-if="costs">
              <span class="text-caption">Costs: </span>
              <span class="text-body-1">${{ costs }}</span>
            </span>
          </span>
        </v-col>
        <v-col cols="4">
          <span v-if="editMode && !onlyNameAndDescriptionEditable">
            <v-text-field
              v-model="costsMin"
              label="Minimum"
              outlined
              :error-messages="costsMinErrors"
              required
            />
          </span>
          <span v-else>
            <span v-if="costsMin">
              <span class="text-caption">Minimum: </span>
              <span class="text-body-1">${{ costsMin }}</span>
            </span>
          </span>
        </v-col>
        <v-col cols="4">
          <span v-if="editMode && !onlyNameAndDescriptionEditable">
            <v-text-field
              v-model="costsMax"
              label="Maximum"
              outlined
              :error-messages="costsMaxErrors"
              required
            />
          </span>
          <span v-else>
            <span v-if="costsMax">
              <span class="text-caption">Maximum: </span>
              <span class="text-body-1">${{ costsMax }}</span>
            </span>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <span v-if="editMode && !onlyNameAndDescriptionEditable">
            <v-text-field
              v-model="duration"
              label="Duration"
              outlined
              :error-messages="durationErrors"
              required
            />
          </span>
          <span v-else>
            <span v-if="duration">
              <span class="text-caption">Duration: </span>
              <span class="text-body-1">{{ duration }} min</span>
            </span>
          </span>
        </v-col>
        <v-col cols="4">
          <span v-if="editMode && !onlyNameAndDescriptionEditable">
            <v-text-field
              v-model="durationMin"
              label="Minimum"
              outlined
              :error-messages="durationMinErrors"
              required
            />
          </span>
          <span v-else>
            <span v-if="durationMin">
              <span class="text-caption">Minimum: </span>
              <span class="text-body-1">{{ durationMin }} min</span>
            </span>
          </span>
        </v-col>
        <v-col cols="4">
          <span v-if="editMode && !onlyNameAndDescriptionEditable">
            <v-text-field
              v-model="durationMax"
              label="Maximum"
              outlined
              :error-messages="durationMaxErrors"
              required
            />
          </span>
          <span v-else>
            <span v-if="durationMax">
              <span class="text-caption">Maximum: </span>
              <span class="text-body-1">{{ durationMax }} min</span>
            </span>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <span v-if="editMode">
            <TransferTypeSelection
              v-model="transferType"
              :v$="v$"
            />
          </span>
          <span v-else>
            <span v-if="transferType">
              <span class="text-caption">Type: </span>
              <span class="text-body-1">{{ transferType.name }}</span>
            </span>
          </span>
        </v-col>
        <v-col cols="4">
          <span v-if="editMode">
            <AgencySelection
              v-model:selected-values="agencies"
              :v$="v$"
            />
          </span>
          <span v-else>
            <span v-if="agencies.length > 0">
              <span class="text-caption">Agencies: </span>
              <span class="text-body-1">{{ agenciesNames.join(", ") }}</span>
            </span>
          </span>
        </v-col>
      </v-row>
      <v-row v-if="editMode">
        <v-col cols="12">
          <v-btn
            color="primary"
            @click="handleSave"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import { getErrorMessages } from '@/utils/validationUtils';
import { maxLength, minLength } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import QuillTextarea from '@/components/QuillTextarea.vue';
import { mapTransferTypeId } from '@/config/transferTypes';
import TransferTypeSelection from '@/components/TransferTypeSelection.vue';
import AgencySelection from '@/components/AgencySelection.vue';

export default {
  name: 'RouteDataCard',
  components: { AgencySelection, TransferTypeSelection, QuillTextarea },
  props: {
    uuid: String,
    routeData: Object,
    handleRefreshData: Function,
    onlyNameAndDescriptionEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: this.routeData.name,
      description: this.routeData.description,
      costsValue: this.routeData.costs,
      costsMin: this.routeData.costsMin,
      costsMax: this.routeData.costsMax,
      costs: this.routeData.costs,
      duration: this.routeData.duration,
      durationMin: this.routeData.durationMin,
      durationMax: this.routeData.durationMax,
      transferType: mapTransferTypeId(this.routeData.transferType !== undefined ? this.routeData.transferType : this.routeData.transferTypeId),
      loading: false,
      submitError: null,
      editMode: false,
      v$: useVuelidate(),
      agencies: [...(this.routeData.agencies || []).map((t) => t.uuid)],
      agenciesNames: [...(this.routeData.agencies || []).map((t) => t.name)],
      fileUploadPath: null,
    };
  },
  validations: {
    name: {
      maxLength: maxLength(50),
      minLength: minLength(3),
    },
    description: {
      maxLength: maxLength(50),
      minLength: minLength(0),
    },
    costs: {},
    costsMin: {},
    costsMax: {},
    duration: {},
    durationMin: {},
    durationMax: {},
    agencies: {},
  },
  computed: {
    nameErrors() {
      return getErrorMessages(this.v$.name, 'Name');
    },
    descriptionErrors() {
      return getErrorMessages(this.v$.description, 'Description');
    },
    costsErrors() {
      return getErrorMessages(this.v$.costs, 'Costs');
    },
    costsMinErrors() {
      return getErrorMessages(this.v$.costsMin, 'Costs Min');
    },
    costsMaxErrors() {
      return getErrorMessages(this.v$.costsMax, 'Costs Max');
    },
    durationErrors() {
      return getErrorMessages(this.v$.duration, 'Duration');
    },
    durationMinErrors() {
      return getErrorMessages(this.v$.durationMin, 'Duration Min');
    },
    durationMaxErrors() {
      return getErrorMessages(this.v$.durationMax, 'Duration Max');
    },
  },
  created() {
    this.touchIgnoreableElements();
  },
  methods: {
    buildRequestObject() {
      return {
        name: this.name,
        description: this.description,
        duration: this.duration,
        durationMin: this.durationMin,
        durationMax: this.durationMax,
        costs: this.costs,
        costsMin: this.costsMin,
        costsMax: this.costsMax,
        transferTypeId: this.transferType.id,
        agencies: this.agencies,
      };
    },
    handleSave() {
      axios.put(`${getBackendAdminHost(this.$store)}/admin/route/${this.uuid}`, this.buildRequestObject(), { withCredentials: true })
        .then(() => {
          this.handleRefreshData();
          this.editMode = false;
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    handleEdit() {
      this.editMode = true;
    },
    touchIgnoreableElements() {
      if (this.v$.agencies) {
        this.v$.agencies.$touch();
      }
    },
  },
};
</script>
