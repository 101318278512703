<template>
  <v-autocomplete
    v-model="autocompleteModel"
    item-title="name"
    :items="transferTypesList"
    :error-messages="transferTypeErrors"
    label="Transfer type"
    :auto-select-first="false"
    :return-object="true"
    :multiple="false"
    :readonly="readOnly"
    @blur="v$.transferTypes?.$touch()"
  />
</template>

<script>

import axios from 'axios';
import { geoBaseTypesAsList } from '@/config/geoBaseTypes';
import { transferTypesAsList } from '@/config/transferTypes';

export default {
  name: 'TransferTypeSelection',
  props: {
    selectedValue: Number,
    transferTypeErrors: Array,
    v$: Object,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      transferTypesList: transferTypesAsList(),
    };
  },
  computed: {
    autocompleteModel: {
      get() {
        return (this.selectedValue != null && this.transferTypesList.find((transferType) => transferType.id === this.selectedValue)) || null;
      },
      set(value) {
        if (this.v$.transferType) {
          this.v$.transferType.$touch();
        }
        this.$emit('update:selectedValue', value.id);
      },
    },
  },
};
</script>
