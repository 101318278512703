<template>
  <GeoEntityView
    ref="geoEntityView"
    :geo-base-type="geoBaseType"
    info-text-general="All accommodations"
  />
</template>

<script>

import GeoEntityView from '@/views/GeoEntityView.vue';
import { geoBaseTypes } from '@/config/geoBaseTypes';

export default {
  components: {
    GeoEntityView,
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.geoEntityView.beforeRouteLeave(to, from, next);
  },
  data() {
    return ({
      geoBaseType: geoBaseTypes.ACCOMMODATION,
    });
  },

};
</script>
